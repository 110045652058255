import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const CommissionSetting = Loadable(lazy(() => import('./CommissionSetting')))

const commissionRoutes = [
    {
        path: '/commission',
        element: <CommissionSetting />,
    },
]

export default commissionRoutes
