import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const CustomerCompanyList = Loadable(
    lazy(() => import('./CustomerCompanyList'))
)
const PaymentTermList = Loadable(lazy(() => import('./PaymentTermList')))

const customerRoutes = [
    {
        path: '/customer/list',
        element: <CustomerCompanyList />
    },
    {
        path: '/customer/payment_term/list',
        element: <PaymentTermList />
    },
]

export default customerRoutes
