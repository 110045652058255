import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { makeStyles } from '@mui/styles'
import SearchIcon from '@mui/icons-material/Search'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { InputBase, IconButton, Paper, Tooltip, Avatar, Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Brand from '../../../components/Brand/Brand'
import useWebstoreData from 'app/hooks/useWebstoreData'
import useWebstoreSearch from 'app/hooks/useWebstoreSearch'

const HeaderSection = styled('div')(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 30px',
}))

const SeachSection = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    right: 0,
    top: 10,
    '@media (max-width: 800px)': {
        marginTop: 20,
        position: 'relative',
        justifyContent: 'center',
        top: 0,
    },
}))

const NavSection = styled('div')(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 0',
    '@media (max-width: 1220px)': {
        justifyContent: 'flex-start',
    },
    '@media (max-width: 800px)': {
        justifyContent: 'center',
    },
}))

const useStyles = makeStyles({
    link: {
        fontSize: 16,
        padding: '7px 30px',
        color: '#63656e',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#E2EDF1',
        },
        '@media (max-width: 1420px)': {
            padding: '7px 15px',
        },
        '@media (max-width: 780px)': {
            padding: '5px 10px',
        },
    },
    navbar_wrapper: {
        position: 'relative',
        '@media (max-width: 800px)': {
            display: 'flex',
            flexDirection: 'column-reverse',
        },
    },
    search_box_style: {
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        width: 260,
        boxShadow: '0px 5px 6px -7px rgb(0 0 0 / 100%)',

        '@media (max-width: 1380px)': {
            width: 200,
        },

        '@media (max-width: 1200px)': {
            width: 260,
        },
    },
})

const Header = ({ clickable }) => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const location = useLocation()
    const { shop_code, type } = useParams()
    const { shop_data } = useWebstoreData()
    const { get_searchKey } = useWebstoreSearch()
    const [anchorEl, setAnchorEl] = useState(null)
    const [userAvatar, setUserAvatar] = useState(true)
    const [searchValue, setSearchValue] = useState('')
    var shop_info_local = JSON.parse(localStorage.getItem('shopInfo'))
    const [shopInfo, setShopInfo] = useState(shop_info_local)
    const open = Boolean(anchorEl)

    useEffect(() => {
        if (shop_data && shop_data.length > 0) {
            setShopInfo(shop_data[0])
        }
    }, [shop_data])


    const disable_click = (e) => {
        if (clickable) return
        e.preventDefault()
    }

    const logIn_navigate = (e) => {
        if (!clickable) return
        navigate('/login')
    }

    const disable_link = (e) => {
        if (clickable) return
        e.preventDefault()
    }

    const send_search_data = () => {
        if (location.pathname.includes('cart')) {
            navigate('/webstore/shop/' + shop_code)
        }
    }

    useEffect(() => {
        setSearchValue('')
    }, [shop_code, type])

    return (
        <>
            <HeaderSection>
                <Link
                    to={'/webstore/shop/' + shop_code}
                    onClick={(e) => disable_click(e)}
                >
                    {shopInfo?.logo_url ? (
                        <img src={shopInfo?.logo_url} alt="logo" width={100} />
                    ) : (
                        <Brand> </Brand>
                    )}
                </Link>
            </HeaderSection>

            <Container maxWidth="xl">
                <div className={classes.navbar_wrapper}>
                    <NavSection>
                        <Link
                            className={classes.link}
                            to={`/webstore/shop/${shop_code}/product`}
                            onClick={(e) => disable_link(e)}
                        >
                            {t('navbar.product')}
                        </Link>

                        <Link
                            className={classes.link}
                            to={`/webstore/shop/${shop_code}/service`}
                            onClick={(e) => disable_link(e)}
                        >
                            {t('navbar.service')}
                        </Link>

                        <Link
                            className={classes.link}
                            to={`/webstore/shop/${shop_code}/package`}
                            onClick={(e) => disable_link(e)}
                        >
                            {t('navbar.package')}
                        </Link>

                        <Link
                            className={classes.link}
                            to={`/webstore/shop/${shop_code}/credit`}
                            onClick={(e) => disable_link(e)}
                        >
                            {t('navbar.credit')}
                        </Link>
                    </NavSection>
                    <SeachSection>
                        <Paper
                            component="div"
                            className={classes.search_box_style}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder={t('webstore.search')}
                                inputProps={{ 'aria-label': 'Search' }}
                                readOnly={!clickable}
                                value={searchValue}
                                onChange={(e) => {
                                    get_searchKey(e.target.value, type)
                                    setSearchValue(e.target.value)
                                }}
                            />
                            <IconButton
                                type="button"
                                sx={{ p: '5px 10px' }}
                                aria-label="search"
                                onClick={send_search_data}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Paper>

                        <>
                            {userAvatar && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Tooltip>
                                        <IconButton
                                            onClick={logIn_navigate}
                                            size="small"
                                            sx={{ ml: 2 }}
                                            aria-controls={
                                                open
                                                    ? 'account-menu'
                                                    : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                                open ? 'true' : undefined
                                            }
                                        >
                                            <Avatar
                                                sx={{ width: 40, height: 40 }}
                                            >
                                                S
                                            </Avatar>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )}
                            {
                                // clickable &&
                                // <Menu
                                //     anchorEl={anchorEl}
                                //     id="account-menu"
                                //     open={open}
                                //     onClose={handleClose}
                                //     onClick={handleClose}
                                //     PaperProps={{
                                //         elevation: 0,
                                //         sx: {
                                //                 overflow: 'visible',
                                //                 filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                //                 mt: 1.5,
                                //                 '& .MuiAvatar-root': {
                                //                     width: 32,
                                //                     height: 32,
                                //                     ml: -0.5,
                                //                     mr: 1,
                                //                 },
                                //             '&:before': before_style
                                //         },
                                //     }}
                                //     transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                //     anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                // >
                                //     <MenuItem onClick={handleClose}>
                                //         <ListItemIcon>
                                //             <Logout fontSize="small" />
                                //         </ListItemIcon>
                                //         Logout
                                //     </MenuItem>
                                // </Menu>
                            }
                        </>
                    </SeachSection>
                </div>
            </Container>
        </>
    )
}

export default Header
