import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'


const initialState = {
    reminderList: [],
    removeReminderIDS: []
}

const Appointment = createSlice({
    name: 'appointment',
    initialState,
    reducers: {
        fetchAppointmentReminder: (state, { payload }) => {
            state.reminderList = payload
        },
        onChangeAppointment: (state, { payload }) => {
            state.reminderList = state.reminderList.map(
                reminder => {
                    if(reminder.id === payload.id){
                        return {
                            ...reminder,
                            [payload.key]: payload.value
                        }
                    }
                    return reminder
                }
            )
        },
        addNewItemToList: (state, { payload }) => {
            state.reminderList = [
                ...state.reminderList,
                payload
            ]
        },
        removeItemFromList: (state, { payload }) => {
            state.reminderList = state.reminderList.filter(reminder => !payload.includes(reminder.id))
        },
        appendErrors: (state, { payload }) => {
            state.reminderList = state.reminderList.map(reminder => {
                if(reminder.id == payload.id) {
                    return {
                        ...reminder,
                        errors: {
                            ...reminder.errors,
                            [payload.name]: payload.error
                        }
                    }
                }
                return reminder
            })
        },
        clearErrors: (state, { payload }) => {
            state.reminderList = state.reminderList.map(reminder => {
                return {
                    ...reminder,
                    errors: null
                }
                
            })
        },
        submittingStatusChange: (state, { payload }) => {
            state.reminderList = state.reminderList.map(reminder => {
                if(payload.submission_status === 'loading'){
                    return {...reminder, submission_status: payload.submission_status}
                } else if(payload.submission_status && payload.id === reminder.id){
                    return {...reminder, submission_status: payload.submission_status}
                }
                return reminder
            })
        },
        changeDemoAndAddedStatusID: (state, {payload}) => {
            state.reminderList = state.reminderList.map(reminder => {
                if(reminder.id === payload.demoID){
                    return {
                        ...reminder,
                        id: payload.dbID,
                        newly_added: false
                    }
                }
                return reminder
            })
        },
        addRemovedDataIDS: (state, {payload}) => {
            state.removeReminderIDS = payload
        },
        clearLanguages: (state, {payload}) => {
            state.reminderList = state.reminderList.map(reminder => {
                return {
                    ...reminder,
                    waba_template: "",
                    waba_template_show: "",
                    submission_status : null
                }
            })
        }

    },
})

export const {
    fetchAppointmentReminder,
    onChangeAppointment,
    addNewItemToList,
    removeItemFromList,
    appendErrors,
    submittingStatusChange,
    changeDemoAndAddedStatusID,
    addRemovedDataIDS,
    clearErrors,
    clearLanguages
} = Appointment.actions

export default Appointment.reducer
