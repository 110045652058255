import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const NotFound = Loadable(lazy(() => import('./NotFound')))
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')))
const DashboardLogin = Loadable(lazy(() => import('./DashboardLogin')))
const SignUp = Loadable(lazy(() => import('./SignUp')))
const WebStoreLogin = Loadable(lazy(() => import('./WebStoreLogin')))

const sessionRoutes = [
    {
        path: 'signup',
        element: <SignUp />,
    },
    {
        path: 'dashboard_login',
        element: <DashboardLogin />,
    },
    {
        path: 'login',
        element: <WebStoreLogin />,
    },
    {
        path: 'forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/session/404',
        element: <NotFound />,
    },
]

export default sessionRoutes
