import { Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Palette from 'app/palette';
import { PlaceTwoTone } from '@mui/icons-material';
import { styled } from '@mui/styles';

const MainContainer = styled(Stack)({
    position: 'fixed',
    top: 0,
    width: '100%',
    height: 15,
    zIndex: 1000
})

const Label = styled(Typography)({
    textAlign: 'center',
    fontSize: 10,
    color: 'white',
    fontWeight: 'bold'
})

const NetworkIndicator = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // Define event handlers
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        // Add event listeners for online and offline events
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Clean up event listeners on component unmount
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return (
        !isOnline &&
        <MainContainer sx={{ backgroundColor: isOnline ? Palette.success : Palette.warning }}>
            <Label>{isOnline ? 'You are online' : 'You are offline'}</Label>
        </MainContainer>
    );
}

export default NetworkIndicator;
