
const initialState = {
    userLockStatus: false
}
const GlobalDataReducer = function (state = initialState, action) {
    switch (action.type) {
        case 'LOCKED_USER': {
            return {
                ...state,
                userLockStatus: true
            }
        }
        case 'UNLOCKED_USER': {
            return {
                ...state,
                userLockStatus: false
            }
        }
        default: {
            return state
        }
    }
}

export default GlobalDataReducer
