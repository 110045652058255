import {
    GET_SHOP_STORAGE,
    GET_SHOP_INFO,
    SHOP_LIST_STORAGE
} from '../actions/ShopInfoAction';

const initialState = {
    shop_storage : {},
    shop_information : {},
    shop_list: []
}

const ShopInfo = function (state = initialState, action) {
    switch (action.type) {
        case SHOP_LIST_STORAGE: {
            return {
                ...state,
                shop_list: action.payload,
            }
        }
        case GET_SHOP_STORAGE: {
            return {
                ...state,
                shop_storage: {...action.payload},
            }
        }
        case GET_SHOP_INFO: {
            return {
                ...state,
                shop_information: {...action.payload},
            }
        }
        default: {
            return state
        }
    }
}

export default ShopInfo