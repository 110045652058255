import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const VoidReport = Loadable(lazy(() => import('./VoidReport')))
const DailyReport = Loadable(lazy(() => import('./DailyReport')))
const HourlyReport = Loadable(lazy(() => import('./HourlyReport')))
const WeeklyReport = Loadable(lazy(() => import('./WeeklyReport')))
const YearlyReport = Loadable(lazy(() => import('./YearlyReport')))
const CreditReport = Loadable(lazy(() => import('./CreditReport')))
const MonthlyReport = Loadable(lazy(() => import('./MonthlyReport')))
const DiscountReport = Loadable(lazy(() => import('./DiscountReport')))
const AppointmentReport = Loadable(lazy(() => import('./AppointmentReport')))
const OutstandingReport = Loadable(lazy(() => import('./OutstandingReport')))
const StockHistoryReport = Loadable(lazy(() => import('./StockHistoryReport')))
const MemberPackageReport = Loadable(lazy(() => import('./MemberPackageReport')))
const SaleCommissionReport = Loadable(lazy(() => import('./SaleCommissionReport')))

const reportRoutes = [
    {
        path: '/report/hourly_report',
        element: <HourlyReport />
    },
    {
        path: '/report/daily_report',
        element: <DailyReport />
    },
    {
        path: '/report/weekly_report',
        element: <WeeklyReport />
    },
    {
        path: '/report/monthly_report',
        element: <MonthlyReport />
    },
    {
        path: '/report/sale_commission_report',
        element: <SaleCommissionReport />
    },
    {
        path: '/report/stock_history_report',
        element: <StockHistoryReport />
    },
    {
        path: '/report/discount_report',
        element: <DiscountReport />
    },
    {
        path: '/report/member_package_report',
        element: <MemberPackageReport />
    },
    {
        path: '/report/void_report',
        element: <VoidReport />
    },
    {
        path: '/report/credit_report',
        element: <CreditReport />
    },
    {
        path: '/report/outstanding_report',
        element: <OutstandingReport />
    },
    {
        path: '/report/yearly_report',
        element: <YearlyReport />
    },
    {
        path: '/report/appointment_report',
        element: <AppointmentReport />
    },
]

export default reportRoutes
