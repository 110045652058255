import React from 'react'
import { Button, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import Palette from 'app/palette'
import { makeStyles } from '@mui/styles'

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
    maxWidth: 620,
    flexDirection: 'column',
    justifyContent: 'center',
}))

const IMG = styled('img')(() => ({
    width: '100%',
    marginBottom: '32px',
}))

const NotFoundRoot = styled(FlexBox)(() => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh !important',
    backgroundColor: 'white',
}))

const useStyles = makeStyles({
    message: {
        color: Palette.theme,
        '@media (min-width:300px)': {
            fontSize: '2rem',
        },
        '@media (min-width:600px)': {
            fontSize: '3rem',
        },
        '@media (min-width:1000px)': {
            fontSize: '3.5rem',
        },
    },
})

const NotFound = () => {
    const navigate = useNavigate()
    const classes = useStyles()

    return (
        <NotFoundRoot>
            <JustifyBox>
                <Typography className={classes.message} color={Palette.theme}>
                    Oops...
                </Typography>
                <IMG src="/assets/images/illustrations/404.gif" alt="" />

                <Button
                    color="primary"
                    variant="contained"
                    sx={{
                        textTransform: 'capitalize',
                        background: Palette.btnBackground,
                        color: 'white',
                    }}
                    onClick={() => navigate(-1)}
                >
                    Back to Dashboard
                </Button>
            </JustifyBox>
        </NotFoundRoot>
    )
}

export default NotFound
