import React from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next'

const DoubleField = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 20,
    marginBottom: 20,
    '@media (max-width: 1320px)': {
        gridTemplateColumns: '1fr',
    },
    '@media (max-width: 800px)': {
        gridTemplateColumns: '1fr 1fr',
    },
    '@media (max-width: 580px)': {
        gridTemplateColumns: '1fr',
    },
})

const FormBody = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
})

const useStyles = makeStyles({
    text_field: {
        width: '100%',
        marginBottom: 20
    }
})

const CartForm = () => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <>
            <FormBody>
                <div>
                    <DoubleField>
                        <TextField
                            InputLabelProps={{
                                style: { color: '#63656E' },
                            }}
                            label={t('webstore.first_name')}
                            variant="outlined"
                        />
                        <TextField
                            InputLabelProps={{
                                style: { color: '#63656E' },
                            }}
                            label={t('webstore.last_name')}
                            variant="outlined"
                        />
                    </DoubleField>

                    <DoubleField>
                        <TextField
                            InputLabelProps={{
                                style: { color: '#63656E' },
                            }}
                            label={t('webstore.contact')}
                            variant="outlined"
                        />
                        <TextField
                            InputLabelProps={{
                                style: { color: '#63656E' },
                            }}
                            label={t('webstore.email')}
                            type={'email'}
                            variant="outlined"
                        />
                    </DoubleField>

                    <TextField
                        InputLabelProps={{
                            style: { color: '#63656E' },
                        }}
                        label={t('webstore.address_1')}
                        variant="outlined"
                        className={classes.text_field}
                    />

                    <TextField
                        InputLabelProps={{
                            style: { color: '#63656E' },
                        }}
                        label={t('webstore.address_2')}
                        variant="outlined"
                        className={classes.text_field}
                    />
                </div>
            </FormBody>
        </>
    )
}

export default CartForm
