import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios.js'
import Loading from 'app/components/Loading'
import { api_url } from 'app/utils/constant'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    accessToken: null
}

const setSession = (loginInfo) => {
    if (loginInfo) {
        localStorage.setItem('loginInfo', JSON.stringify(loginInfo))
        localStorage.setItem('activeUser', JSON.stringify(loginInfo.user))
        axios.defaults.headers.common.Authorization = `token ${loginInfo.accessToken}`
    } else {
        localStorage.removeItem('activeUser')
        localStorage.removeItem('loginInfo')
        localStorage.removeItem('shopInfo')
        localStorage.removeItem('currencyInfo')
        localStorage.removeItem('taxInfo')
        localStorage.removeItem("dashBoardDefautlTime")
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user, accessToken } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
                accessToken
            }
        }
        case 'LOGIN': {
            const { user, accessToken } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
                accessToken
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                accessToken: null
            }
        }
        case 'REGISTER': {
            const { user, accessToken } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
                accessToken
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    webstore_login: () => Promise.resolve(),
    dashboard_login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const dashboard_login = async (username, password) => {
        await axios
            .post(`${api_url}dashboard_login/`, {
                username,
                password,
            })
            .then((returnData) => {
                let { user } = returnData.data
                setSession(returnData.data) // storing the userInfo and accessToken
                dispatch({
                    type: 'LOGIN',
                    payload: returnData.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const webstore_login = async (username, password) => {
        await axios
            .post(`${api_url}webstore_login/`, {
                username,
                password,
            })
            .then((returnData) => {
                let { user } = returnData.data
                setSession(returnData.data) // storing the userInfo and accessToken
                dispatch({
                    type: 'LOGIN',
                    payload: returnData.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const register = async (username, password) => {
        await axios
            .post(`${api_url}register/`, {
                username,
                password,
            })
            .then((returnData) => {
                let { user } = returnData.data
                setSession(returnData.data)

                dispatch({
                    type: 'REGISTER',
                    payload: returnData.data,
                })
            })
    }

    const logout = async () => {
        const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
        await axios
            .post(
                `${api_url}logout/`,
                {},
                {
                    headers: {
                        Authorization: `token ${loginInfo.accessToken}`,
                    },
                }
            )
            .then((returnData) => {
                setSession(null)
                dispatch({ type: 'LOGOUT' })
            })
            .catch((error) => {
                setSession(null)
                dispatch({ type: 'LOGOUT' })
            })
    }

    useEffect(() => {
        ; (async () => {
            try {
                const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))

                if (loginInfo.accessToken) {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: loginInfo?.user,
                            accessToken: loginInfo?.accessToken
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                            accessToken: null
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        accessToken: null
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <Loading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                webstore_login,
                dashboard_login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
