import axios from 'axios.js';
import { api_url } from '../../utils/constant';

export const GET_SHOP_STORAGE = 'GET_SHOP_STORAGE';
export const GET_SHOP_INFO = 'GET_SHOP_INFO';
export const SHOP_LIST_STORAGE = 'SHOP_LIST_STORAGE'

var loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
var shopInfo = JSON.parse(localStorage.getItem('shopInfo'))

function update_localStorage_data() {
    loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
    shopInfo = JSON.parse(localStorage.getItem('shopInfo'))
}

export const getShopStorage = (shopID) => async (dispatch) => {
    update_localStorage_data()

    function convertAndCalculate(sizeInMb) {
        let size, unit;
        let thresholdInGB = 0.1;
        let totalStorage = 50;

        if (sizeInMb < thresholdInGB) {
            size = sizeInMb * 1024;
            unit = 'MB';
        } else {
            size = sizeInMb;
            unit = 'GB';
        }

        let percentage = (sizeInMb / totalStorage) * 100;

        return {
            used_size: `${size.toFixed(2)} ${unit}`,
            parcentage: Number(percentage.toFixed(2)),
            totalStorage: totalStorage + ' GB'
        }
    }

    axios
        .get(`${api_url}shop/file-size/`, {
            headers: {
                Authorization: `token ${loginInfo.accessToken}`,
            },
            params: { id: shopID || shopInfo?.id }, //shop_id
        })
        .then((res) => {
            if (res.data?.success) {
                dispatch({
                    type: GET_SHOP_STORAGE,
                    payload: convertAndCalculate(res.data?.total_size || 0),
                })
            }
        })
};


export const getShopInformation = (data) => (dispatch) => {
    dispatch({
        type: GET_SHOP_INFO,
        payload: data
    })
}