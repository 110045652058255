import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const PackageList = Loadable(lazy(() => import('./PackageList')))

const packageRoutes = [
    {
        path: '/package/list',
        element: <PackageList />
    },
]

export default packageRoutes
