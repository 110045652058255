import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const OrderList = Loadable(lazy(() => import('./OrderList')))
const CartList = Loadable(lazy(() => import('./CartList')))
const WebStore = Loadable(lazy(() => import('./WebStore')))
const Checkout = Loadable(lazy(() => import('./Checkout')))
const Cart = Loadable(lazy(() => import('./UserProfile/Cart')))
const MyPurchase = Loadable(lazy(() => import('./UserProfile/MyPurchase')))
const UserProfile = Loadable(lazy(() => import('./UserProfile/UserProfile')))

const webStoreRoutes = [
    {
        path: '/webstore',
        element: <WebStore />
    },
    {
        path: '/webstore/order_list',
        element: <OrderList />
    },
    {
        path: '/webstore/cart_list',
        element: <CartList />
    },
    {
        path: '/webstore/user_profile',
        element: <UserProfile />
    },
    {
        path: '/webstore/my_purchase',
        element: <MyPurchase />
    },
    {
        path: '/webstore/cart',
        element: <Cart />
    },
    {
        path: '/webstore/checkout',
        element: <Checkout />
    },
]

export default webStoreRoutes
