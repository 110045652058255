import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const Referral = Loadable(lazy(() => import('./Referral')))
const MemberList = Loadable(lazy(() => import('./MemberList')))
const Membership = Loadable(lazy(() => import('./Membership')))
const MemberBirthday = Loadable(lazy(() => import('./MemberBirthday')))

const memberRoutes = [
    {
        path: '/member/list',
        element: <MemberList />,
    },
    {
        path: '/member/birthday',
        element: <MemberBirthday />,
    },
    {
        path: '/membership',
        element: <Membership />,
    },
    {
        path: '/referral',
        element: <Referral />,
    },
]

export default memberRoutes
