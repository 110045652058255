import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const TermsOfUse = Loadable(lazy(() => import('./TermsOfUse')))
const PrivacyPolicy = Loadable(lazy(() => import('./PrivacyPolicy')))

const termsPolicyRoutes = [
    {
        path: 'privacy_policy',
        element: <PrivacyPolicy />,
    },
    {
        path: 'terms_of_use',
        element: <TermsOfUse />,
    },
]

export default termsPolicyRoutes
