import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { updateCartItem } from '../../../../app/redux/actions/WebstoreAction'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    payment_btn: {
        background:
            'linear-gradient(81.62deg, rgb(49, 56, 96) 2.25%, rgb(21, 25, 40) 79.87%)',
        color: '#fff',
        padding: '13px 80px',
        display: 'inline-block',
        marginTop: 30,
        fontSize: 18,
        '@media (max-width: 800px)': {
            fontSize: 16,
        },
    },
    input_number_box: {
        fontSize: 14,
        width: 65,
        padding: '13px 5px',
        color: '#63656E',
        outline: 'none',
        border: '1px solid #63656E',
        borderRadius: 3,
        textAlign: 'center',
        fontFamily: 'Roboto',
    },
    table_bottom_border: {
        borderBottom: 'none',
    },
    btn_center: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
    },
})

const CartList = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [taxRate, setTaxRate] = useState(0)
    const [taxName, setTaxName] = useState('')
    const [subTotal, setSubTotal] = useState(0)
    const [taxTotal, setTaxTotal] = useState(0)
    const cart_list = useSelector((e) => e?.webstoreData?.cart_list)
    const shop_data = useSelector((e) => e?.webstoreData?.shop_data)

    const remove_cart = (e) => {
        const index_num = cart_list.findIndex((k) => e === k.uid)
        let result = [...cart_list]
        result.splice(index_num, 1)
        dispatch(updateCartItem(result))
    }

    useEffect(() => {
        let cal_subtotal = 0

        cart_list.length > 0 &&
            cart_list.map((value) => {
                if (value?.price && value?.quantity) {
                    cal_subtotal =
                        cal_subtotal +
                        Number(value?.price) * Number(value?.quantity)
                }
            })

        setSubTotal(cal_subtotal)
    }, [cart_list])

    const changing_quantity = (id, value) => {
        const index_num = cart_list.findIndex((k) => id === k.uid)
        let result = [...cart_list]
        result[index_num].quantity = value
        dispatch(updateCartItem(result))
    }

    useEffect(() => {
        if (shop_data && shop_data.length > 0) {
            let taxt_rate =
                ((shop_data[0]?.merchant?.country?.tax?.rate || 1) * subTotal) /
                100

            setTaxTotal(taxt_rate)
            setTaxName(shop_data[0]?.merchant?.country?.tax?.name || '')
            setTaxRate(shop_data[0]?.merchant?.country?.tax?.rate || 0)
        }
    }, [shop_data, subTotal])

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('webstore.description')}</TableCell>
                            <TableCell align="right">
                                {t('webstore.unit_price')}
                            </TableCell>
                            <TableCell align="right">
                                {t('webstore.quantity')}
                            </TableCell>
                            <TableCell align="right">
                                {t('webstore.price')}
                            </TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {cart_list.length > 0 &&
                            cart_list.map((value, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Avatar
                                                alt="Remy Sharp"
                                                src={
                                                    value?.image_url ||
                                                    '/assets/images/no_image.jpg'
                                                }
                                                variant="square"
                                                sx={{
                                                    width: 56,
                                                    height: 56,
                                                    marginBottom: 1,
                                                }}
                                            />
                                            {value?.name || ''}
                                        </TableCell>
                                        <TableCell align="right">
                                            {value?.price || ''}
                                        </TableCell>
                                        <TableCell align="right">
                                            <input
                                                className={
                                                    classes.input_number_box
                                                }
                                                defaultValue={
                                                    value?.quantity || 0
                                                }
                                                min={1}
                                                type="number"
                                                onChange={(e) =>
                                                    changing_quantity(
                                                        value?.uid,
                                                        Math.abs(e.target.value)
                                                    )
                                                }
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            {value?.price &&
                                                value?.quantity &&
                                                (
                                                    Number(value?.price) *
                                                    Number(value?.quantity)
                                                ).toFixed(2)}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                color="primary"
                                                component="label"
                                                onClick={() =>
                                                    remove_cart(value?.uid)
                                                }
                                            >
                                                <ClearIcon color="warning" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}

                        <TableRow>
                            <TableCell
                                className={classes.table_bottom_border}
                                rowSpan={3}
                            />
                            <TableCell colSpan={2}>
                                {t('webstore.subtotal')}
                            </TableCell>
                            <TableCell align="right">
                                {subTotal?.toFixed(2)}
                            </TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>
                                {' '}
                                {taxName} {t('webstore.tax')}(%)
                            </TableCell>
                            <TableCell align="right">
                                {' '}
                                {taxRate?.toFixed(2)}{' '}
                            </TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                className={classes.table_bottom_border}
                                colSpan={2}
                            >
                                <b>{t('webstore.total')}</b>
                            </TableCell>
                            <TableCell
                                className={classes.table_bottom_border}
                                align="right"
                            >
                                {subTotal === 0
                                    ? 0
                                    : (taxTotal + subTotal).toFixed(2)}
                            </TableCell>
                            <TableCell
                                className={classes.table_bottom_border}
                                align="right"
                            ></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <div className={classes.btn_center}>
                <Button className={classes.payment_btn} variant="contained">
                    {t('webstore.payment')}
                </Button>
            </div>
        </>
    )
}

export default CartList
