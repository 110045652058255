import {useEffect} from 'react';
import useWebstoreData from './useWebstoreData';
import {useParams, useNavigate} from 'react-router-dom';
import {setSearchData} from 'app/redux/actions/WebstoreAction'
import {useDispatch, useSelector} from 'react-redux'


let isSearched = false

function useWebstoreSearch() {
    
    const {
        packageData,
        productData,
        serviceData,
        creditData
    } = useWebstoreData();
    const shop_data = useSelector(e => e.webstoreData?.search_data)

    
    const { shop_code , type } = useParams()

    
    const dispatch = useDispatch()

    
    const get_searchKey = (search_key, type) => {
        let totalData = []

        if(type === "service"){
            totalData = [ ...serviceData ]
        }else if(type === "product"){
            totalData = [ ...productData ]
        }else if(type === "package"){
            totalData = [ ...packageData ]
        }else if(type === "credit"){
            totalData = [ ...creditData ]
        }else{
            totalData = [ ...packageData, ...productData, ...serviceData, ...creditData ]
        };
        
        if(totalData && totalData.length > 0 && search_key?.length > 0){
            const result = totalData.filter( e => e?.name?.toLowerCase()?.includes(search_key?.toLowerCase()));
            isSearched = true;
            dispatch(setSearchData(result))
        }else{
            isSearched = false;
            dispatch(setSearchData([]))
        };
    };


    useEffect(() => {
        if(shop_data?.length !== 0){
            dispatch(setSearchData([]))
            isSearched = false
        }
    },[type, shop_code])


    return {
        isSearched,
        searchData : shop_data,
        get_searchKey
    }
}

export default useWebstoreSearch