import React from 'react';
import { styled } from '@mui/system';
import { Container, Divider } from '@mui/material';
import Header from './WebStoreComponent/Header';
import CartForm from './WebStoreComponent/CartForm'
import CartList from './WebStoreComponent/CartList'



const Body = styled('div')({
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'white',
    color: '#63656e',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 8,
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 6%),0px 3px 4px 0px rgb(0 0 0 / 4%),0px 1px 8px 0px rgb(0 0 0 / 4%)',
    padding: '40px 16px',
    minHeight: 'calc(100vh - 200px)',
    '@media (max-width: 800px)': {
        flexDirection: 'column',
        gap: 30
    }
})

const WrapperBoxLeft = styled('div')({
    width: '40%',
    padding: '10px 30px',
    '@media (max-width: 800px)': {
        width: '100%',
        padding: '10px',
    },
})

const WrapperBoxRight = styled('div')({
    width: '60%',
    padding: '10px 30px',
    '@media (max-width: 800px)': {
        width: '100%',
        padding: '10px',
    },
})

const EmptyBox = styled('div')({
    height: 200
})

const WebStoreCart = () => {
    return (
        <>
            <Header clickable={true} />

            <Container maxWidth="xl">
                <Body>
                    <WrapperBoxLeft>
                        <CartForm />
                    </WrapperBoxLeft>
                    <Divider orientation="vertical" flexItem />
                    <WrapperBoxRight>
                        <CartList />
                    </WrapperBoxRight>
                </Body>
            </Container>
            <EmptyBox></EmptyBox>
        </>
    )
}

export default WebStoreCart
