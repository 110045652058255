import React from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
    cartLink: {
        position: 'fixed',
        right: 60,
        bottom: 80,
        background: '#fff',
        borderRadius: '100%',
        zIndex: 99,
        border: '1px solid #00B9C6',
        '@media (max-width: 780px)': {
            right: 20,
            bottom: 30,
        },
    },
    iconButton: {
        padding: 13,
        position: 'relative',
        color: '#00B9C6',
        '@media (max-width: 780px)': {
            padding: 10,
        }
    },
    totoal_item: {
        top: -8,
        right: -4,
        position: 'absolute',
        fontSize: 14,
        width: 25,
        height: 25,
        background: '#f1d2bc',
        display: 'grid',
        placeItems: 'center',
        borderRadius: '100%'
    }
})


const CartIcon = () => {
    const classes = useStyles()
    const cart_list = useSelector(e => e?.webstoreData?.cart_list);
    const { shop_code } = useParams()

    return (
        <Link to={`/webstore/shop/${shop_code}/cart`} className={classes.cartLink}>
            <IconButton className={classes.iconButton}>
                {
                    cart_list.length > 0 &&
                    <span className={classes.totoal_item}> {cart_list.length} </span>
                }
                <ShoppingCartIcon />
            </IconButton>
        </Link>
    )
}

export default CartIcon
