import {
    GET_SERVICE_LIST,
    GET_PRODUCT_LIST,
    GET_CREDIT_LIST,
    GET_PACKAGE_LIST,
    GET_BANNER_LIST,
    GET_CART_LIST,
    UPDATE_CART_LIST,
    GET_SHOP_DATA,
    GET_SEARCH_DATA
} from '../actions/WebstoreAction';



const initialState = {
    banner_img_list : [],
    cart_list : [],
    search_data : []
}

const WebStoreData = function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_LIST: {
            return {
                ...state,
                product_list: [...action.payload],
            }
        }
        case GET_SERVICE_LIST: {
            return {
                ...state,
                service_list: [...action.payload],
            }
        }
        case GET_CREDIT_LIST: {
            return {
                ...state,
                credit_list: [...action.payload],
            }
        }
        case GET_PACKAGE_LIST: {
            return {
                ...state,
                package_list: [...action.payload],
            }
        }
        case GET_BANNER_LIST: {
            return {
                ...state,
                banner_img_list: [...action.payload],
            }
        }
        case GET_CART_LIST: {
            return {
                ...state,
                cart_list: [...state.cart_list, action.payload],
            }
        }
        case UPDATE_CART_LIST: {
            return {
                ...state,
                cart_list: [...action.payload],
            }
        }
        case GET_SHOP_DATA: {
            return {
                ...state,
                shop_data: [...action.payload],
            }
        }
        case GET_SEARCH_DATA: {
            return {
                ...state,
                search_data: [...action.payload],
            }
        }
        default: {
            return state
        }
    }
}

export default WebStoreData