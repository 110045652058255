import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const CountryList = Loadable(lazy(() => import('./CountryList')))
const CurrencyList = Loadable(lazy(() => import('./CurrencyList')))
const TaxList = Loadable(lazy(() => import('./TaxList')))
const PhoneCodeList = Loadable(lazy(() => import('./PhoneCodeList')))

const masterRoutes = [
    {
        path: '/master/country',
        element: <CountryList />
    },
    {
        path: '/master/currency',
        element: <CurrencyList />
    },
    {
        path: '/master/tax',
        element: <TaxList />
    },
    {
        path: '/master/phone_code',
        element: <PhoneCodeList />
    },
]

export default masterRoutes
