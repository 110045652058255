import _ from 'lodash';

const init_languages = [
    {
        name : "English",
        value : "en"
    },
    {
        name : "English (US)",
        value : "en_US"
    },
    {
        name : "English (UK)",
        value : "en_GB"
    },
    {
        name : "Chinese (CHN)",
        value : "zh_CN"
    },
    {
        name : "Chinese (HKG)",
        value : "zh_HK"
    },
    {
        name : "Chinese (TAI)",
        value : "zh_TW"
    },
    {
        name : "Bahasa Malaysia",
        value : "ms"
    },
    { 
        name : "Afrikaans",
        value : "af"
    },
    { 
        name : "Albanian",
        value : "sq"
    },
    {	
        name : "Arabic",
        value : "ar"
    },
    {	
        name : "Azerbaijani",
        value : "az"
    },
    {
        name : "Bengali",
        value : "bn"
    },
    {
        name : "Bulgarian",
        value : "bg"
    },
    {
        name : "Catalan",
        value : "ca"
    },
    {
        name : "Croatian",
        value : "hr"
    },
    {
        name : "Czech",
        value : "cs"
    },
    {
        name : "Danish",
        value : "da"
    },
    {
        name : "Dutch",
        value : "nl"
    },
    {
        name : "Estonian",
        value : "et"
    },
    {
        name : "Filipino",
        value : "fil"
    },
    {
        name : "Finnish",
        value : "fi"
    },
    {
        name : "French",
        value : "fr"
    },
    {
        name : "Georgian",
        value : "ka"
    },
    {
        name : "German",
        value : "de"
    },
    {
        name : "Greek",
        value : "el"
    },
    {
        name : "Gujarati",
        value : "gu"
    },
    {
        name : "Hausa",
        value : "ha"
    },
    {
        name : "Hebrew",
        value : "he",
    },
    {
        name : "Hindi",
        value : "hi"
    },
    {
        name : "Hungarian",
        value : "hu"
    },
    {			
        name : "Indonesian",
        value : "id"
    },
    {	
        name : "Irish",
        value : "ga"
    },	
    {
        name : "Italian",
        value : "it"
    },
    {	
        name : "Japanese",
        value : "ja"
    },
    {	
        name : "Kannada",
        value : "kn"
    },
    {	
        name : "Kazakh",
        value : "kk"
    },
    {	
        name : "Kinyarwanda",
        value : "rw_RW"
    },
    {	
        name : "Korean",
        value : "ko"
    },
    {	
        name : "Kyrgyz (Kyrgyzstan)",
        value : "ky_KG"
    },
    {	
        name : "Lao",
        value : "lo"
    },
    {	
        name : "Latvian",
        value : "lv"
    },
    {	
        name : "Lithuanian",
        value : "lt"
    },
    {	
        name : "Macedonian",
        value : "mk"
    },
    {	
        name : "Malay",
        value : "ms"
    },
    {
        name : "Malayalam",
        value : "ml"
    },
    {	
        name : "Marathi",
        value : "mr"
    },
    {	
        name : "Norwegian",
        value : "nb"
    },
    {	
        name : "Persian",
        value : "fa"
    },
    {	
        name : "Polish",
        value : "pl"
    },
    {	
        name : "Portuguese (BR)",
        value : "pt_BR"
    },
    {	
        name : "Portuguese (POR)",
        value : "pt_PT"
    },
    {	
        name : "Punjabi",
        value : "pa"
    },
    {	
        name : "Romanian",
        value : "ro"
    },
    {	
        name : "Russian",
        value : "ru"
    },
    {	
        name : "Serbian",
        value : "sr"
    },
    {	
        name : "Slovak",
        value : "sk"
    },
    {
        name : "Slovenian",
        value : "sl"
    },
    {
        name : "Spanish",
        value : "es"
    },
    {
        name : "Spanish (ARG)",
        value : "es_AR"
    },
    {
        name : "Spanish (SPA)",
        value : "es_ES"
    },
    {
        name : "Spanish (MEX)",
        value : "es_MX"
    },
    {
        name : "Swahili",
        value : "sw"
    },
    {
        name : "Swedish",
        value : "sv"
    },
    {
        name : "Tamil",
        value : "ta"
    },
    {
        name : "Telugu",
        value : "te"
    },
    {
        name : "Thai",
        value : "th"
    },
    {
        name : "Turkish",
        value : "tr"
    },
    {
        name : "Ukrainian",
        value : "uk"
    },
    {
        name : "Urdu",
        value : "ur"
    },
    {
        name : "Uzbek",
        value : "uz"
    },
    {
        name : "Vietnamese",
        value : "vi"
    },
    {
        name : "Zulu",
        value : "zu"
    }
]

export const wabaLanguages = _.sortBy(init_languages, 'name');