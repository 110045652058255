import React, { createContext, useReducer, useEffect } from 'react'
import axios from 'axios.js'
import { api_url } from 'app/utils/constant'

export const MerchantContext = createContext()

const initialState = {
    business_type: [],
    active_business_data: null,
    adding_business_data: {
        name: '',
        permissions: [],
    },
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'BUSINESS_TYPE_LIST':
            return {
                ...state,
                business_type: action.payload,
            }

        case 'ACTIVE_BUSINESS_DATA':
            return {
                ...state,
                active_business_data: {
                    ...state.active_business_data,
                    ...action.payload,
                },
            }
        case 'ADDING_BUSINESS_DATA':
            return {
                ...state,
                adding_business_data: {
                    ...state.adding_business_data,
                    ...action.payload,
                },
            }
        default:
            return state
    }
}

const MerchantContextProvider = ({ children }) => {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
    const [merchantState, merchantContextDispatch] = useReducer(
        reducer,
        initialState
    )

    useEffect(() => {
        if (merchantState.business_type.length === 0) {
            axios
                .get(`${api_url}business_type/`, {
                    headers: {
                        Authorization: `token ${loginInfo?.accessToken}`,
                    },
                })
                .then((data) => {
                    merchantContextDispatch({
                        type: 'BUSINESS_TYPE_LIST',
                        payload: data.data,
                    })

                    merchantContextDispatch({
                        type: 'ACTIVE_BUSINESS_DATA',
                        payload: data.data[0],
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [])

    return (
        <MerchantContext.Provider
            value={[merchantState, merchantContextDispatch]}
        >
            {children}
        </MerchantContext.Provider>
    )
}

export default MerchantContextProvider
