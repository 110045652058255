import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const ChatRoom = Loadable(lazy(() => import('./ChatRoom')))
const CreditReload = Loadable(lazy(() => import('./CreditReload')))
const TemplateList = Loadable(lazy(() => import('./TemplateList')))
const MarketingList = Loadable(lazy(() => import('./MarketingList')))
const CreateMode = Loadable(lazy(() => import('./Template/CreateMode')))

const staffRoutes = [
    {
        path: 'waba/chatroom/*',
        element: <ChatRoom />,
    },
    {
        path: 'waba/marketing',
        element: <MarketingList />,
    },
    {
        path: 'waba/template',
        element: <TemplateList />,
    },
    {
        path: 'waba/credit_reload',
        element: <CreditReload />,
    },
    {
        path: 'waba/create-template',
        element: <CreateMode />,
    },
]

export default staffRoutes
