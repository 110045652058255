import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const Quotation = Loadable(lazy(() => import('./Quotation')))
const History = Loadable(lazy(() => import('./History')))
const QuotationPDF = Loadable(lazy(() => import('./QuotationPDF')))

const quotationRoutes = [
    {
        path: '/quotation',
        element: <Quotation />,
    },
    {
        path: '/quotation/history',
        element: <History />,
    },
    {
        path: '/quotation_doc',
        element: <QuotationPDF />,
    },
]

export default quotationRoutes
