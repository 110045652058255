import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NavigationReducer from './NavigationReducer'
import WebstoreReducer from './WebstoreReducer'
import ShopInfo from './ShopInfoReducer'
import GlobalDataReducer from './GlobalDataReducer'
import BillingAndInvoiceSlice from '../slice/BillingAndInvoiceSlice'
import QuotationAndDOSlice from '../slice/QuotationAndDOSlice'
import WabaTemplateSlice from '../slice/WabaTemplateSlice'
import ChatRoomSlice from '../slice/ChatRoomSlice'
import Appointment from '../slice/Appointment'
import MemberPageEditReducer from './MemberPageEditReduces'
import MemberReducer from './MemberReducer'
import StaffPageEditReducer from './StaffPageEditReducer'
import UserRoleInfo from './RoleReducer'
import TaskStatusReducer from './tasksDataReducer'

const RootReducer = combineReducers({
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    webstoreData: WebstoreReducer,
    shopInfo: ShopInfo,
    globalData: GlobalDataReducer,
    billing_invoice: BillingAndInvoiceSlice,
    quotation_do: QuotationAndDOSlice,
    waba_template: WabaTemplateSlice,
    chat_room: ChatRoomSlice,
    appointment: Appointment,
    memberPageEdit: MemberPageEditReducer,
    staffPageEdit: StaffPageEditReducer,
    memberData: MemberReducer,
    userRoleInfo: UserRoleInfo,
    taskStatusReducer: TaskStatusReducer
})

export default RootReducer
