import React from 'react'
import { Box, styled } from '@mui/system'
import { CircularProgress } from '@mui/material'
import Palette from 'app/palette'

const StyledLoading = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        width: 'auto',
        height: '25px',
    },
    '& .circleProgress': {
        position: 'absolute',
        left: 0,
        right: 0,
        color: Palette.theme,
    },
}))

const Loading = () => {
    return (
        <StyledLoading>
            <Box position="relative">
                <img
                    src="/assets/images/logo-circle-300.png"
                    alt=""
                    style={{ width: 45, height: 45 }}
                />
                <CircularProgress className="circleProgress" style={{width: 45, height: 45}}/>
            </Box>
        </StyledLoading>
    )
}

export default Loading
