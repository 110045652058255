import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const ExpenseList = Loadable(lazy(() => import('./ExpenseList')))
const ExpenseHistory = Loadable(lazy(() => import('./ExpenseHistory')))

const expenseRoutes = [
    {
        path: '/expense/list',
        element: <ExpenseList />
    },
    {
        path: '/expense/history',
        element: <ExpenseHistory />
    },
]

export default expenseRoutes
