import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import {
    getServiceList,
    getCreditList,
    getPackageList,
    getProductList,
    getShopData
} from '../redux/actions/WebstoreAction'

let sendRequest = false

function useWebstoreData() {

    const [productData, setProductData] = useState([])
    const [packageData, setPackageData] = useState([])
    const [creditData, setCreditData] = useState([])
    const [serviceData, setServiceData] = useState([])

    const {shop_code} = useParams()
    const dispatch = useDispatch()

    const product_data = useSelector(e => e?.webstoreData?.product_list)
    const package_data = useSelector(e => e?.webstoreData?.package_list)
    const credit_data = useSelector(e => e?.webstoreData?.credit_list)
    const service_data = useSelector(e => e?.webstoreData?.service_list)
    const shop_data = useSelector(e => e?.webstoreData?.shop_data)

    useEffect(()=>{
        if([0, undefined, null].includes(shop_data?.length) && shop_code){
            dispatch(getShopData(shop_code))
        }
    },[]);

    useEffect(()=>{
        if(![0, undefined, null].includes(shop_data?.length) && !sendRequest){
            sendRequest = true;

            if([0, undefined, null].includes(product_data?.length)) dispatch(getProductList(shop_data[0]?.id));
            if([0, undefined, null].includes(package_data?.length)) dispatch(getPackageList(shop_data[0]?.id));
            if([0, undefined, null].includes(credit_data?.length)) dispatch(getCreditList(shop_data[0]?.id));
            if([0, undefined, null].includes(service_data?.length)) dispatch(getServiceList(shop_data[0]?.id));
        }
    },[shop_data, sendRequest]);


    useEffect(()=>{
        if( ![0, undefined, null].includes(product_data?.length) ){
            const temp = []
            product_data.map((value)=> {
                var obj = {
                    ...value,
                    image_url : value?.product_images[0]?.image_url || '',
                    tag_name : 'product'
                }
                temp.push(obj)
            })
            setProductData(temp)
        };
    },[product_data])


    useEffect(()=>{
        if( ![0, undefined, null].includes(package_data?.length) ){
            const temp = []
            package_data.map((value)=> {
                var obj = {
                    ...value,
                    tag_name : 'package'
                }
                temp.push(obj)
            })
            setPackageData(temp)
        };
    },[package_data])


    useEffect(()=>{
        if( ![0, undefined, null].includes(credit_data?.length) ){
            const temp = []
            credit_data.map((value)=> {
                var obj = {
                    ...value,
                    tag_name : 'credit'
                }
                temp.push(obj)
            })
            setCreditData(temp)
        };
    },[credit_data])


    useEffect(()=>{
        if( ![0, undefined, null].includes(service_data?.length) ){
            const temp = []
            service_data.map((value)=> {
                var obj = {
                    ...value,
                    tag_name : 'service'
                }
                temp.push(obj)
            })
            setServiceData(temp)
        };
    },[service_data])


    return {
        productData,
        packageData,
        creditData,
        serviceData,
        product_data,
        package_data,
        credit_data,
        service_data,
        shop_data
    }
}

export default useWebstoreData
