import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import ProductDetails from './ProductDetails'

const WebstoreDetail = Loadable(lazy(() => import('./index')))

const webstoreDetailRoutes = [
    {
        path: ':type',
        element: <WebstoreDetail/>,
    },
    {
        path: ':type/:id',
        element: <ProductDetails/>,
    }
]

export default webstoreDetailRoutes
