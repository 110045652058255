import React from 'react'
import { Button, Snackbar, Typography } from '@mui/material'
import Palette from 'app/palette'
import { CustomSnackbarContent } from 'app/utils/customStyle'
import { useTranslation } from 'react-i18next'

const NotificationBar = ({ open, handleUpdate = () => { } }) => {
    const { t } = useTranslation()

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
        >
            <CustomSnackbarContent
                message={t('main.new_update_available')}
                action={
                    <Button variant="contained" onClick={handleUpdate}>
                        <Typography sx={{ color: Palette.success, fontWeight: 'bold' }}>{t('main.update')}</Typography>
                    </Button>
                }
            />
        </Snackbar>
    )
}

export default NotificationBar