import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const CredtList = Loadable(lazy(() => import('./CreditList')))

const creditRoutes = [
    {
        path: '/credit/list',
        element: <CredtList />,
    },
]

export default creditRoutes
