import {
    Badge,
    RecentActors,
    Assessment,
    Favorite,
    CalendarMonth,
    MonetizationOn,
    Assignment,
    Dashboard,
    AccountBalance,
    SettingsApplications,
    LocalShipping,
    AutoAwesome,
    Diamond,
    Web,
    Tune,
    Toll,
    ReceiptLong,
    Business,
    Percent,
    WhatsApp,
} from '@mui/icons-material'

export const ALL_NAVIGATION = [
    {
        name: 'navigations.master_data',
        icon: <SettingsApplications fontSize="medium" />,
        children: [
            {
                name: 'navigations.country',
                iconText: 'SI',
                path: '/master/country',
                value: 'master_data_country',
            },
            {
                name: 'navigations.currency',
                iconText: 'SI',
                path: '/master/currency',
                value: 'master_data_currency',
            },
            {
                name: 'navigations.tax',
                iconText: 'SI',
                path: '/master/tax',
                value: 'master_data_tax',
            },
            {
                name: 'navigations.phone_code',
                iconText: 'SI',
                path: '/master/phone_code',
                value: 'master_data_phone_code',
            },
        ],
    },
    {
        name: 'navigations.dashboard',
        path: '/dashboard',
        icon: <Dashboard fontSize="medium" />,
        value: 'dashboard',
    },
    {
        name: 'navigations.appointment',
        icon: <CalendarMonth fontSize="medium" />,
        children: [
            {
                name: 'navigations.appointment',
                iconText: 'SI',
                path: '/appointment/',
                value: 'appointment_appointment',
                staff_permissions: ["appointmentM_appointmentP_view"]
            },
            {
                name: 'navigations.archived_list',
                iconText: 'SI',
                path: '/appointment/archived_list',
                value: 'appointment_archived_list',
                staff_permissions: ["appointmentM_archived_listP_view"]
            },
            {
                name: 'navigations.reminder_setting',
                iconText: 'SI',
                path: '/appointment/reminder',
                value: 'appointment_reminder',
            },
        ],
    },
    {
        name: 'navigations.member',
        icon: <RecentActors fontSize="medium" />,
        children: [
            {
                name: 'navigations.member',
                iconText: 'SI',
                path: '/member/list',
                value: 'member_member',
                staff_permissions: ["memberM_member_listP_view_all_member"]
            },
            {
                name: 'navigations.member_birthday',
                iconText: 'SI',
                path: '/member/birthday',
                value: 'member_member_birthday',
                staff_permissions: ["memberM_member_birthdayP_view"],
            },
            {
                name: 'navigations.membership',
                iconText: 'SI',
                path: '/membership',
                value: 'member_membership',
                staff_permissions: ["memberM_membership_rewardP_view"]
            },
            {
                name: 'navigations.referral',
                iconText: 'SI',
                path: '/referral',
                value: 'member_referral',
            },
        ],
    },
    {
        name: 'WABA',
        icon: <WhatsApp fontSize="medium" />,
        children: [
            {
                name: 'navigations.chatroom',
                iconText: 'SI',
                path: '/waba/chatroom',
                value: 'waba_chatroom',
                staff_permissions: ["WABAM_chatroomP_chat_room_access"]
            },
            {
                name: 'navigations.whatsapp_template',
                iconText: 'SI',
                path: '/waba/template',
                value: 'waba_whatsapp_template',
                staff_permissions: ["WABAM_whatsApp_templateP_view"]
            },
            {
                name: 'navigations.whatsapp_marketing',
                iconText: 'SI',
                path: '/waba/marketing',
                value: 'waba_whatsapp_marketing',
                staff_permissions: ["WABAM_whatsApp_marketingP_view"]
            },
            {
                name: 'navigations.waba_credit_reload',
                iconText: 'SI',
                path: '/waba/credit_reload',
                value: 'waba_credit_reload',
            },
        ],
    },
    {
        name: 'navigations.billing',
        icon: <MonetizationOn fontSize="medium" />,
        children: [
            {
                name: 'navigations.billing',
                iconText: 'SI',
                path: '/billing/',
                value: 'billing_billing',
                staff_permissions: ["billingM_billingP_create"]
            },
            {
                name: 'navigations.billing_history',
                iconText: 'SI',
                path: '/billing/history',
                value: 'billing_billing_history',
                staff_permissions: ["billingM_billing_historyP_view"]
            },
        ],
    },
    // {
    //     name: 'navigations.invoice',
    //     icon: <MonetizationOn fontSize="medium" />,
    //     children: [
    //         {
    //             name: 'navigations.invoice',
    //             iconText: 'SI',
    //             path: '/invoice/',
    //             value: 'invoice_invoice',
    //         },
    //         {
    //             name: 'navigations.invoice_history',
    //             iconText: 'SI',
    //             path: '/invoice/history',
    //             value: 'invoice_invoice_history',
    //         },
    //     ],
    // },
    // {
    //     name: 'navigations.delivery_order',
    //     icon: <LocalShipping fontSize="medium" />,
    //     children: [
    //         {
    //             name: 'navigations.delivery_order',
    //             iconText: 'SI',
    //             path: '/delivery_order/',
    //             value: 'delivery_order_delivery_order',
    //         },
    //         {
    //             name: 'navigations.delivery_order_history',
    //             iconText: 'SI',
    //             path: '/delivery_order/history',
    //             value: 'delivery_order_history',
    //         },
    //     ],
    // },
    {
        name: 'navigations.quotation',
        icon: <Assignment fontSize="medium" />,
        children: [
            {
                name: 'navigations.quotation',
                iconText: 'SI',
                path: '/quotation/',
                value: 'quotation_quotation',
                staff_permissions: ["quotationM_quotationP_create"]
            },
            {
                name: 'navigations.quotation_history',
                iconText: 'SI',
                path: '/quotation/history',
                value: 'quotation_quotation_history',
                staff_permissions: ["quotationM_quotation_historyP_view"]
            },
        ],
    },
    {
        name: 'navigations.service',
        path: '/service/list',
        icon: <AutoAwesome fontSize="medium" />,
        value: 'service',
        staff_permissions: ["serviceM_serviceP_view"]
    },
    {
        name: 'navigations.package',
        path: '/package/list',
        icon: <Diamond fontSize="medium" />,
        value: 'package',
        staff_permissions: ["packageM_packageP_view"]
    },
    {
        name: 'navigations.credit',
        path: '/credit/list',
        icon: <Toll fontSize="medium" />,
        value: 'credit',
        staff_permissions: ["creditM_creditP_view"]
    },
    {
        name: 'navigations.inventory',
        icon: <LocalShipping fontSize="medium" />,
        children: [
            {
                name: 'navigations.product_list',
                iconText: 'SI',
                path: '/inventory/product_list',
                value: 'inventory_product_list',
                staff_permissions: ["inventoryM_product_listP_view"]
            },
            {
                name: 'navigations.stock_history',
                iconText: 'SI',
                path: '/inventory/history',
                value: 'inventory_stock_history',
                staff_permissions: ["inventoryM_stock_inP_view", "inventoryM_stock_outP_view", "inventoryM_stock_adjustmentP_view", "inventoryM_purchase_orderP_view"]
            },
        ],
    },
    {
        name: 'navigations.report',
        icon: <Assessment fontSize="medium" />,
        children: [
            // {
            //     name: 'navigations.hourly_report',
            //     iconText: 'SI',
            //     path: '/report/hourly_report',
            //     value: 'report_hourly_report',
            // },
            {
                name: 'navigations.daily_report',
                iconText: 'SU',
                path: '/report/daily_report',
                value: 'report_daily_report',
                staff_permissions: ["reportM_daily_reportP_view"]
            },
            {
                name: 'navigations.weekly_report',
                iconText: 'FP',
                path: '/report/weekly_report',
                value: 'report_weekly_report',
                staff_permissions: ["reportM_weekly_reportP_view"]
            },
            {
                name: 'navigations.monthly_report',
                iconText: '404',
                path: '/report/monthly_report',
                value: 'report_monthly_report',
                staff_permissions: ["reportM_monthly_reportP_view"]
            },
            {
                name: 'navigations.appointment_report',
                iconText: '404',
                path: '/report/appointment_report',
                value: 'report_appointment_report',
                staff_permissions: ["reportM_appointment_reportP_view"]
            },
            {
                name: 'navigations.member_package_report',
                iconText: '404',
                path: '/report/member_package_report',
                value: 'report_member_package_balance_report',
                staff_permissions: ["reportM_member_package_balance_reportP_view"]
            },
            {
                name: 'navigations.stock_history_report',
                iconText: '404',
                path: '/report/stock_history_report',
                value: 'report_stock_history_report',
                staff_permissions: ["reportM_stock_history_reportP_view"]
            },
            {
                name: 'navigations.sale_commission_report',
                iconText: '404',
                path: '/report/sale_commission_report',
                value: 'report_sale_commission_report',
                staff_permissions: ["reportM_sale_commission_reportP_view"]
            },
            {
                name: 'navigations.outstanding_report',
                iconText: '404',
                path: '/report/outstanding_report',
                value: 'report_outstanding_report',
                staff_permissions: ["reportM_outstanding_reportP_view"]
            },
            {
                name: 'navigations.void_report',
                iconText: '404',
                path: '/report/void_report',
                value: 'report_void_report',
                staff_permissions: ["reportM_void_reportP_view"]
            },
            {
                name: 'navigations.discount_report',
                iconText: '404',
                path: '/report/discount_report',
                value: 'report_discount_report',
                staff_permissions: ["reportM_discount_reportP_view"]
            },
            // {
            //     name: 'navigations.credit_report',
            //     iconText: '404',
            //     path: '/report/credit_report',
            //     value: 'report_credit_report',
            // },
            // {
            //     name: 'navigations.yearly_report',
            //     iconText: '404',
            //     path: '/report/yearly_report',
            //     value: 'report_yearly_report',
            // },
        ],
    },
    {
        name: 'navigations.commission',
        icon: <Percent fontSize="medium" />,
        path: '/commission',
        value: 'commission',
        staff_permissions: ["commissionM_commissionP_view"]
    },
    {
        name: 'navigations.expense',
        icon: <ReceiptLong fontSize="medium" />,
        children: [
            {
                name: 'navigations.expense_list',
                iconText: 'SI',
                path: '/expense/list',
                value: 'expense_expense_list',
                staff_permissions: ["expenseM_expense_listP_view"]
            },
            {
                name: 'navigations.expense_history',
                iconText: 'SI',
                path: '/expense/history',
                value: 'expense_expense_history',
                staff_permissions: ["expenseM_expense_historyP_view"]
            },
        ],
    },
    {
        name: 'navigations.customer_company',
        icon: <Business fontSize="medium" />,
        children: [
            {
                name: 'navigations.customer_company',
                iconText: 'SI',
                path: '/customer/list',
                value: 'customer_company_customer_company',
            },
            {
                name: 'navigations.payment_term',
                iconText: 'SI',
                path: '/customer/payment_term/list',
                value: 'customer_company_payment_term',
            },
        ],
    },
    {
        name: 'navigations.staff',
        icon: <Badge fontSize="medium" />,
        children: [
            {
                name: 'navigations.staff_list',
                iconText: 'SI',
                path: '/staff/list',
                value: 'staff_staff_list',
                staff_permissions: [
                    "staffM_staff_listP_view",
                    "staffM_staff_listP_view_all_staff"
                ]
            },
            {
                name: 'Staff Role',
                iconText: 'SI',
                path: '/staff/role',
                value: 'staff_staff_role',
                staff_permissions: [
                    "staffM_staff_roleP_view"
                ]
            },
            // {
            //     name: 'navigations.daily_timecard',
            //     iconText: 'SU',
            //     path: '/staff/daily_timecard',
            //     value: 'staff_daily_timecard',
            // },
            // {
            //     name: 'navigations.monthly_timecard',
            //     iconText: 'FP',
            //     path: '/staff/monthly_timecard',
            //     value: 'staff_monthly_timecard',
            // },
        ],
    },
    {
        name: 'navigations.merchant',
        icon: <AccountBalance fontSize="medium" />,
        children: [
            {
                name: 'navigations.merchant_list',
                iconText: 'SI',
                path: '/merchant/list',
                value: 'merchant_merchant_list',
            },
            {
                name: 'navigations.shop_list',
                iconText: 'SI',
                path: '/merchant/shop_list',
                value: 'merchant_shop_list',
            },
            {
                name: 'navigations.business_type',
                iconText: 'SI',
                path: '/merchant/business_type',
                value: 'merchant_business_type',
            },
        ],
    },
    {
        name: 'navigations.webstore',
        icon: <Web fontSize="medium" />,
        children: [
            {
                name: 'navigations.webstore',
                iconText: 'SI',
                path: '/webstore',
                value: 'webstore_webstore',
            },
            {
                name: 'navigations.checkout',
                iconText: 'SI',
                path: '/webstore/checkout',
                value: 'webstore_checkout',
            },
            {
                name: 'navigations.order_list',
                iconText: 'SU',
                path: '/webstore/order_list',
                value: 'webstore_order_list',
            },
            {
                name: 'navigations.shopping_cart',
                iconText: 'FP',
                path: '/webstore/cart',
                value: 'webstore_shopping_cart',
            },
        ],
    },
    {
        name: 'navigations.shop_setting',
        icon: <Tune fontSize="medium" />,
        children: [
            {
                name: 'navigations.discount',
                iconText: 'SI',
                path: '/setting/discount_list',
                value: 'shop_settings_discount',
            },
            {
                name: 'navigations.coupon',
                iconText: 'SI',
                path: '/setting/coupon_list',
                value: 'shop_settings_coupon',
            },
            {
                name: 'navigations.room',
                iconText: 'SI',
                path: '/setting/room_list',
                value: 'shop_settings_room',
            },
            {
                name: 'navigations.marketing_channel',
                iconText: 'SI',
                path: '/setting/channel_list',
                value: 'shop_settings_marketing_channel',
            },
            {
                name: 'navigations.payment_method',
                iconText: 'SI',
                path: '/setting/payment_method_list',
                value: 'shop_settings_payment_method',
            },
            {
                name: 'navigations.system_security_lock',
                iconText: 'SI',
                path: '/setting/security_lock',
                value: 'shop_settings_system_security_lock',
            },
        ],
    },
]
