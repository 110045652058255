import axios from 'axios.js';
import { getShopStorage } from './ShopInfoAction'
import { api_url, api_url_unauth } from '../../utils/constant';

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_SERVICE_LIST = 'GET_SERVICE_LIST';
export const GET_PACKAGE_LIST = 'GET_PACKAGE_LIST';
export const GET_CREDIT_LIST = 'GET_CREDIT_LIST';
export const GET_BANNER_LIST = 'GET_BANNER_LIST';
export const GET_CART_LIST = 'GET_CART_LIST';
export const UPDATE_CART_LIST = 'UPDATE_CART_LIST';
export const GET_SHOP_DATA = 'GET_SHOP_DATA';
export const GET_SEARCH_DATA = 'GET_SEARCH_DATA';


var loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
var shopInfo = JSON.parse(localStorage.getItem('shopInfo'))


function update_localStorage_data() {
    loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
    shopInfo = JSON.parse(localStorage.getItem('shopInfo'))
}


export const getShopData = (shopCode) => (dispatch) => {
    axios
        .get(`${api_url_unauth}shop/shop_code/`, {
            params: { shop_code: shopCode }, //shop_code
        })
        .then((res) => {
            dispatch({
                type: GET_SHOP_DATA,
                payload: res.data,
            })
        })
};


export const getProductList = (shopID) => (dispatch) => {
    update_localStorage_data()

    axios.get(`${api_url}product_list/`, {
        // headers: {
        //     Authorization: `token ${loginInfo.accessToken}`,
        // },
        params: { 
            sid: shopID || shopInfo?.id,
            data_type: 'available'
        },
    })
    .then((res) => {
        dispatch({
            type: GET_PRODUCT_LIST,
            payload: res.data,
        })
    })
}


export const getServiceList = (shopID) => (dispatch) => {
    update_localStorage_data()

    axios
    .get(`${api_url}service_list/`, {
        // headers: {
        //     Authorization: `token ${loginInfo.accessToken}`,
        // },
        params: { 
            sid: shopID ||  shopInfo?.id,//shop_id
            data_type: 'available'  
        },
    })
    .then((res) => {
        dispatch({
            type: GET_SERVICE_LIST,
            payload: res.data,
        })
    })
};


export const getPackageList = (shopID) => (dispatch) => {
    update_localStorage_data()

    axios
    .get(`${api_url}package_list/`, {
        // headers: {
        //     Authorization: `token ${loginInfo.accessToken}`,
        // },
        params: { 
            sid: shopID || shopInfo?.id, //shop_id
            data_type: 'available' 
        }, 
    })
    .then((res) => {
        dispatch({
            type: GET_PACKAGE_LIST,
            payload: res.data,
        })
    })
};


export const getCreditList = (shopID) => (dispatch) => {
    update_localStorage_data()

    axios
    .get(`${api_url}credit_list/`, {
        // headers: {
        //     Authorization: `token ${loginInfo.accessToken}`,
        // },
        params: { 
            sid: shopID || shopInfo?.id, // shop_id
            data_type: 'available'
        }, 
    })
    .then((res) => {
        dispatch({
            type: GET_CREDIT_LIST,
            payload: res.data,
        })
    })
};


export const setCartItem = (data) => (dispatch) => {
    dispatch({
        type: GET_CART_LIST,
        payload: data,
    })
};

export const updateCartItem = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_CART_LIST,
        payload: data,
    })
};

export const setSearchData = (data) => (dispatch) => {
    dispatch({
        type: GET_SEARCH_DATA,
        payload: data,
    })
};


export const getBannerImage = (shopID) => (dispatch) => {
    update_localStorage_data()

    axios
        .get(`${api_url}carousel_image/`, {
            params: { sid: shopInfo?.code || shopID }, //shop_id
        })
        .then((res) => {
            dispatch({
                type: GET_BANNER_LIST,
                payload: res.data,
            })
        })
};


export const setBannerImage = ({ data, allBannerImages }) => async (dispatch) => {
    update_localStorage_data()

    return (
        axios
            .post(`${api_url}carousel_image/manage/`, data, {
                headers: {
                    Authorization: `token ${loginInfo.accessToken}`,
                }
            })
            .then((res) => {
                dispatch({
                    type: GET_BANNER_LIST,
                    payload: allBannerImages,
                })

                dispatch(getShopStorage())
                return true
            })
            .catch((error) => {
                return error
            })
    )
};


export const setPreviewBannerImage = (data) => (dispatch) => {
    dispatch({
        type: GET_BANNER_LIST,
        payload: data,
    })
};