import _ from 'lodash'
import { createSlice, current } from '@reduxjs/toolkit'
import {
    wabaLanguages
} from 'app/utils/wabaLanguages'

const initialState = {
    waba_template_title: {
        tm_type: null,
        category: null,
        name: null,
        language: null,
        is_editing: false
    },
    waba_header_components: {},
    waba_body_components: {},
    waba_footer_components: {},
    waba_button_components: {},
    initial_language: wabaLanguages,
    selected_language: []
};

const WabaTemplateSlice = createSlice({
    name: 'waba_template',
    initialState,
    reducers: {
        update_category: (state, { payload }) => {
            state.waba_header_components = {}
            state.waba_body_components = {}
            state.waba_footer_components = {}
            state.waba_button_components = {}
        },
        add_waba_templete_title: (state, { payload }) => {
            let { field, value } = payload
            state.waba_template_title[field] = value
        },
        add_selected_language: (state, { payload }) => {
            state.selected_language = payload
        },
        update_selected_language: (state, { payload }) => {
            state.selected_language = [
                ...state.selected_language,
                payload
            ]
        },
        update_selected_language_field: (state, { payload }) => {
            let { index, field, value } = payload;
            state.selected_language[index][field] = value;
        },
        update_header_content: (state, { payload }) => {
            let { field, value, format } = payload;
            let active_lng = state.waba_template_title.language;

            state.waba_header_components[active_lng] = {
                ...state.waba_header_components[active_lng],
                format,
                type: "HEADER",
                [field]: value
            };
        },
        select_header_format: (state, { payload }) => {
            let { format } = payload;
            let active_lng = state.waba_template_title.language;

            state.waba_header_components[active_lng] = {
                format,
                type: "HEADER",
            };
        },
        set_header_location: (state, { payload }) => {
            let { field, value } = payload;
            let active_lng = state.waba_template_title.language;

            state.waba_header_components[active_lng] = {
                format: "LOCATION",
                type: "HEADER",
                example: {
                    ...state.waba_header_components[active_lng]?.example,
                    [field]: value
                }
            };
        },
        remove_header_content: (state, { payload }) => {
            let active_lng = state.waba_template_title.language;
            const result = _.omit(state.waba_header_components, active_lng);
            state.waba_header_components = result;
        },
        update_body_content: (state, { payload }) => {
            let { field, value } = payload;
            let active_lng = state.waba_template_title.language;

            state.waba_body_components[active_lng] = {
                ...state.waba_body_components[active_lng],
                type: "BODY",
                [field]: value
            };
        },
        update_footer_content: (state, { payload }) => {
            let { field, value } = payload;
            let active_lng = state.waba_template_title.language;
            let temp_category = state.waba_template_title.category;

            let footer_obj = {
                ...state.waba_footer_components[active_lng],
                type: "FOOTER",
                [field]: value
            };


            if (temp_category === "AUTHENTICATION") {
                footer_obj = _.pick(footer_obj, ['type', 'code_expiration_minutes']);
            } else {
                footer_obj = _.pick(footer_obj, ['type', 'text']);
            };

            state.waba_footer_components[active_lng] = footer_obj
        },
        adding_button_content: (state, { payload }) => {
            let active_lng = state.waba_template_title.language;
            let previews_button = state.waba_button_components[active_lng]?.buttons || [];
            let new_button = [
                ...previews_button
            ]

            if(payload === 'website'){
                new_button.push({
                    type: "URL",
                    text: "",
                    url: "",
                    example: []
                })
            }else if(payload === 'number'){
                new_button.push({
                    type: "PHONE_NUMBER",
                    text: "",
                    phone_number : '',
                    phone_code : '+60'
                })
            }else if(payload === 'none'){
                delete state.waba_button_components[active_lng];
                return;
            };

            state.waba_button_components[active_lng] = {
                type : 'BUTTONS',
                buttons : [...new_button]
            };
        },
        sorting_button_content: (state, { payload }) =>{
            let active_lng = state.waba_template_title.language;
            state.waba_button_components[active_lng].buttons = payload;
        },
        update_button_content: (state, { payload }) => {
            let { index , field, value } = payload;
            let active_lng = state.waba_template_title.language;
            state.waba_button_components[active_lng].buttons[index][field] = value;
        },
        remove_button_content :  (state, { payload }) => {
            let active_lng = state.waba_template_title.language;
            state.waba_button_components[active_lng].buttons.splice(payload , 1);

            if(state.waba_button_components[active_lng].buttons?.length === 0){
                delete state.waba_button_components[active_lng];
            };
        },
        components_edit_value: (state, { payload }) => {
            let { type, value } = payload;
            let active_lng = state.waba_template_title.language;
            if (!active_lng) return;

            if (type === "HEADER") {
                state.waba_header_components[active_lng] = value;
            } else if (type === "BODY") {
                state.waba_body_components[active_lng] = value
            } else if (type === "FOOTER") {
                state.waba_footer_components[active_lng] = value
            } else if (type === "BUTTONS") {
                state.waba_button_components[active_lng] = value
            };
        },
        title_editable_value: (state, { payload }) => {
            state.waba_template_title = payload
        },
        editable_selected_language: (state, { payload }) => {
            const get_language_list = _.find(state.initial_language, { value: payload });
            state.selected_language = [get_language_list]
        },
        reset_waba_template: () => {
            return initialState;
        }

    },
})

export const {
    add_waba_templete_title,
    add_selected_language,
    update_selected_language,
    update_body_content,
    update_header_content,
    remove_header_content,
    update_footer_content,
    update_button_content,
    update_category,
    select_header_format,
    set_header_location,
    update_selected_language_field,
    reset_waba_template,
    components_edit_value,
    title_editable_value,
    editable_selected_language,
    adding_button_content,
    remove_button_content,
    sorting_button_content
} = WabaTemplateSlice.actions

export default WabaTemplateSlice.reducer
