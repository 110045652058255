
const initialState = {
    permissions: []
}

const UserRoleInfo = function (state = initialState, action) {
    switch (action.type) {
        case 'FETCH_ROLE_PERMISSIONS': {
            return {
                permissions: action.payload,
            }
        }
        default: {
            return state
        }
    }
}

export default UserRoleInfo