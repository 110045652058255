import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const ServiceList = Loadable(lazy(() => import('./ServiceList')))

const serviceRoutes = [
    {
        path: '/service/list',
        element: <ServiceList />
    },
]

export default serviceRoutes
