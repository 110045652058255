import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { INITIAL_QUOTATION } from 'app/utils/constant'
import { toRound, orderItemCalculator } from 'app/utils/calculation'

const initialState = {
    orderList: INITIAL_QUOTATION,
    staffList: [],
}

const QuotationAndDOSlice = createSlice({
    name: 'quotation_do',
    initialState,
    reducers: {
        addOrderList: (state, { payload }) => {
            state.orderList = payload
        },
        updateItemList: (state, { payload }) => {
            state.orderList.item_list[payload.item_index][payload.field] =
                payload.value

            let count_title = [
                'quantity',
                'price',
                'discount',
                'credit_applied',
            ]

            if (count_title.includes(payload.field)) {
                let { quantity, price, discount, credit_applied } =
                    state.orderList.item_list[payload.item_index]

                let total = (Number(quantity) || 0) * (Number(price) || 0)

                if (discount?.type === 1) {
                    total -= total * (Number(discount.amount) / 100)
                } else if (discount?.type === 2) {
                    total = total - (Number(discount.amount) || 0)
                }

                state.orderList.item_list[payload.item_index].total = total

                if (['discount', 'credit_applied'].includes(payload.field)) {
                    state.orderList.credit_applied = 0
                    state.orderList.discount_amount = 0
                    state.orderList.discount = {}
                }
            }
        },
        removeOrderItem: (state, { payload }) => {
            let { item, index_item } = payload
            _.remove(
                state.orderList.item_list,
                (item, index) => index == index_item
            )
        },
        updatePayment: (state, { payload }) => {
            state.orderList.payment = [...state.orderList.payment, payload]
        },
        removePayment: (state, { payload }) => {
            _.remove(state.orderList.payment, (item, index) => index == payload)
        },
        updatemMethods: (state, { payload }) => {
            let { payment, payment_index } = payload
            state.orderList.payment[payment_index] = payment
        },
        updateOrderParentKey: (state, { payload }) => {
            let { field, value } = payload

            state.orderList[field] = value

            if (field === 'discount') {
                let discountAmount = 0
                let subTotal = state.orderList.subtotal
                if (Number(value.amount)) {
                    if (value.type === 1) {
                        discountAmount = subTotal * (Number(value.amount) / 100)
                    } else if (value.type === 2) {
                        discountAmount = Number(value.amount)
                    }
                }

                state.orderList.discount_amount = discountAmount
            }

            let billing_profile = ['member_profile', 'customer_profile']

            if (billing_profile.includes(field)) {
                let clone_item_list = _.cloneDeep(state.orderList.item_list)
                let get_new_item_list_ = _.map(clone_item_list, (item) => {
                    let item_obj_total =
                        (Number(item.quantity) || 0) * (Number(item.price) || 0)

                    if (item?.payment?.discount?.type === 1) {
                        item_obj_total =
                            item_obj_total -
                            item_obj_total *
                            (Number(item?.payment?.discount.amount) / 100)
                    } else if (item?.payment?.discount?.type === 2) {
                        item_obj_total =
                            item_obj_total - (Number(item?.payment?.discount.amount) || 0)
                    }

                    return {
                        ...item,
                        credit_applied: '',
                        total: item_obj_total,
                    }
                })

                state.orderList.item_list = get_new_item_list_
                state.orderList.credit_applied = 0
            }
        },
        updateBalance: (state, { payload }) => {
            let {
                serviceTotal,
                productTotal,
                packageTotal,
                creditTotal,
                outstandingInvTotal,
            } = orderItemCalculator(state.orderList.item_list)

            let item_payment_ammount = _.map(state.orderList.payment, 'amount')

            let subTotal = serviceTotal + productTotal + packageTotal + outstandingInvTotal + creditTotal

            let payment = _.reduce(
                item_payment_ammount,
                (accumulator, currentNumber) =>
                    (Number(accumulator) || 0) + (Number(currentNumber) || 0),
                0
            )

            let total = subTotal - Number(state.orderList.discount_amount)

            let rounded_total = toRound(total)
            state.orderList.rounding = Number(rounded_total) - total
            state.orderList.subtotal = subTotal
            state.orderList.total = Number(rounded_total)
            state.orderList.payment_amount = payment
            state.orderList.balance = Number(rounded_total) - payment
        },
    },
})

export const {
    addOrderList,
    updateItemList,
    removeOrderItem,
    updatePayment,
    removePayment,
    updatemMethods,
    updateOrderParentKey,
    updateBalance,
} = QuotationAndDOSlice.actions

export default QuotationAndDOSlice.reducer
