import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import ContextProvider from 'app/components/Dialog/Merchant/ContextProvider'

const MerchantList = Loadable(lazy(() => import('./MerchantList')))
const ShopList = Loadable(lazy(() => import('./ShopList')))
const BusinessType = Loadable(lazy(() => import('./BusinessType')))

const merchantRoutes = [
    {
        path: '/merchant/list',
        element: <MerchantList />
    },
    {
        path: '/merchant/shop_list',
        element: (
            <ContextProvider>
                <ShopList />
            </ContextProvider>
        )
    },
    {
        path: '/merchant/business_type',
        element: (
            <ContextProvider>
                <BusinessType />
            </ContextProvider>
        ),
    },
]

export default merchantRoutes
