import React from 'react'
import App from './app/App'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { StyledEngineProvider } from '@mui/styled-engine'
import { CssBaseline } from '@mui/material'

if (!process) {
  process = { env: {} }
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then((registration) => {
        // console.log('ServiceWorker registered with scope:', registration.scope);
      })
      .catch((error) => {
        // console.error('ServiceWorker registration failed:', error);
      });
  });
}


const root = createRoot(document.getElementById('root'))
root.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <CssBaseline />
      <App />
    </BrowserRouter>
  </StyledEngineProvider>
)
