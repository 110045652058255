import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { makeStyles } from '@mui/styles'
import { Container, Button, TextField } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './webStyle.css'
import Header from '../WebStoreComponent/Header'
import CartIcon from '../WebStoreComponent/CartIcon'
import {
    setCartItem,
    updateCartItem,
} from '../../../../app/redux/actions/WebstoreAction'
import useWebstoreData from 'app/hooks/useWebstoreData'

const Body = styled('div')({
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'white',
    color: '#63656e',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 8,
    boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 6%),0px 3px 4px 0px rgb(0 0 0 / 4%),0px 1px 8px 0px rgb(0 0 0 / 4%)',
    padding: '40px 16px',
    '@media (max-width: 800px)': {
        flexDirection: 'column',
        gap: 30,
    },
})

const EmptyBox = styled('div')({
    height: 200,
})

const WrapperBox = styled('div')({
    width: '50%',
    padding: '10px 30px',
    '@media (max-width: 800px)': {
        width: '100%',
        padding: '10px',
    },
})

const MainImage = styled('div')({
    width: '100%',
    height: 460,
    margin: 'auto',
    '@media (max-width: 1200px)': {
        height: 320,
    },
    '@media (max-width: 600px)': {
        height: 220,
    },
    img: {
        borderRadius: 10,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
})

const ImageList = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
    marginTop: 20,

    '@media (max-width: 600px)': {
        gap: 10,
    },

    div: {
        width: 65,
        height: 65,
        cursor: 'pointer',

        '@media (max-width: 1200px)': {
            width: 55,
            height: 55,
        },

        '@media (max-width: 600px)': {
            width: 45,
            height: 45,
        },

        img: {
            borderRadius: 10,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            '@media (max-width: 600px)': {
                borderRadius: 5,
            },
        },
    },
})

const H1 = styled('h1')({
    fontSize: 30,
    color: '#1f2733',
    marginTop: 0,
    '@media (max-width: 800px)': {
        fontSize: 20,
    },
})

const Stock = styled('span')({
    fontSize: 14,
    color: '#22543D',
    background: '#C6F6D5',
    padding: '5px 20px',
    borderRadius: 15,
})

const NonStock = styled('span')({
    fontSize: 14,
    color: '#22543D',
    background: '#eba99b',
    padding: '5px 20px',
    borderRadius: 15,
})

const SortTittle = styled('span')({
    fontSize: 14,
    color: '#A0AEC0',
})

const P = styled('p')({
    display: 'flex',
    gap: 5,
    fontSize: 16,
    color: '#1F2733',
    '@media (max-width: 800px)': {
        fontSize: 14,
    },
    svg: {
        position: 'relative',
        top: 5,
        fontSize: 12,
        '@media (max-width: 800px)': {
            fontSize: 10,
        },
    },
})

const Breadcrumbs = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 5,
    paddingBottom: 20,
    'span, a': {
        fontSize: 16,
        textTransform: 'capitalize',
        color: '#A0AEC0',
    },
    a: {
        color: '#22543D',
    },
    '@media (max-width: 800px)': {
        'span, a': {
            fontSize: 14,
        },
    },
})

const useStyles = makeStyles({
    cart_btn: {
        background:
            'linear-gradient(81.62deg, rgb(49, 56, 96) 2.25%, rgb(21, 25, 40) 79.87%)',
        color: '#fff',
        padding: '10px 50px',

        '@media (max-width: 800px)': {
            fontSize: 14,
            padding: '6px 30px',
        },
    },
    input_box: {
        width: 110,
        marginTop: 30,
        marginBottom: 30,
    },
    input_box_label: {
        color: '#1F2733',
        fontSize: 14,
        fontWeight: 'bold',
        paddingBottom: 10,
    },
    input_text_box: {
        fontSize: 14,
        width: '100%',
        padding: '13px',
        outline: 'none',
        color: '#63656E',
        borderColor: '#A0AEC0',
        borderRadius: 5,
        resize: 'none',
        fontFamily: 'Roboto',
    },
})

const ProductDetails = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const { shop_code, type, id } = useParams()
    const dispatch = useDispatch()
    const [image_data, setImage_data] = useState([])
    const [singleImage, setSingleImage] = useState()
    const [quantityData, setQuantityData] = useState(1)

    const [productInfo, setProductInfo] = useState([])

    const { packageData, creditData, serviceData, productData } =
        useWebstoreData()

    const cart_list = useSelector((e) => e?.webstoreData?.cart_list)
    const image_on_focus = (e) => setSingleImage(e)

    useEffect(() => {
        if (type === 'product') {
            if (productData?.length > 0) {
                const filter_data = productData.filter((e) => e.id === id)
                setProductInfo(filter_data[0])
                setImage_data(filter_data[0]?.product_images)
                setSingleImage(filter_data[0]?.image_url)
            }
        } else if (type === 'service') {
            if (serviceData?.length > 0) {
                const filter_data = serviceData.filter((e) => e.id === id)
                setProductInfo(filter_data[0])
                setSingleImage(filter_data[0]?.image_url)
            }
        } else if (type === 'package') {
            if (packageData?.length > 0) {
                const filter_data = packageData.filter((e) => e.id === id)
                setProductInfo(filter_data[0])
                setSingleImage(filter_data[0]?.image_url)
            }
        } else if (type === 'credit') {
            if (creditData?.length > 0) {
                const filter_data = creditData.filter((e) => e.id === id)
                setProductInfo(filter_data[0])
                setSingleImage(filter_data[0]?.image_url)
            }
        }
    }, [packageData, creditData, serviceData, productData])

    let uid = Math.floor(Math.random() * 999999)

    const add_to_cart = () => {
        if (quantityData < 1) return

        let filter_result = []
        let cart_data_obj = {
            uid: uid,
            id: productInfo?.id,
            name: productInfo?.name,
            price: productInfo?.shop_price,
            image_url: productInfo?.image_url,
            quantity: quantityData,
        }

        if (cart_list.length > 0) {
            filter_result = cart_list.filter(
                (e) =>
                    e.id === productInfo?.id &&
                    e.name === productInfo?.name &&
                    e.price === productInfo?.shop_price &&
                    e.image_url === productInfo?.image_url
            )
        }

        if (filter_result.length > 0) {
            let new_data = {
                ...cart_data_obj,
                quantity:
                    Number(quantityData) + Number(filter_result[0].quantity),
            }

            const index_num = cart_list.findIndex(
                (k) => filter_result[0].uid === k.uid
            )
            let remove_result = [...cart_list]
            remove_result.splice(index_num, 1)

            let attached_data = [...remove_result, new_data]

            dispatch(updateCartItem(attached_data))
            return
        }

        dispatch(setCartItem(cart_data_obj))
    }

    return (
        <>
            <Header clickable={true} />

            <Container maxWidth="xl">
                <Body>
                    <WrapperBox>
                        <Breadcrumbs>
                            <Link to={'/webstore/shop/' + shop_code}>
                                {t('webstore.home')}
                            </Link>
                            <ArrowForwardIosIcon fontSize={'12px'} />
                            <Link
                                to={'/webstore/shop/' + shop_code + '/' + type}
                            >
                                {' '}
                                {type}{' '}
                            </Link>
                            <ArrowForwardIosIcon fontSize={'12px'} />
                            <span> {productInfo?.name || '. . .'} </span>
                        </Breadcrumbs>

                        <MainImage>
                            <img
                                src={
                                    singleImage || '/assets/images/no_image.jpg'
                                }
                                alt="img"
                            />
                        </MainImage>

                        <ImageList>
                            {type === `${t('webstore.product')}` &&
                                image_data?.length > 0 &&
                                image_data.map((value, index) => {
                                    if (index >= 5) return
                                    return (
                                        <div
                                            key={index}
                                            onClick={() =>
                                                image_on_focus(value.image_url)
                                            }
                                            onMouseEnter={() =>
                                                image_on_focus(value.image_url)
                                            }
                                        >
                                            <img
                                                src={value.image_url}
                                                alt="img"
                                            />
                                        </div>
                                    )
                                })}

                            {['service', 'package', 'credit'].includes(
                                type
                            ) && (
                                    <div
                                        onClick={() =>
                                            image_on_focus(
                                                productInfo?.image_url || ''
                                            )
                                        }
                                    >
                                        <img
                                            src={
                                                productInfo?.image_url ||
                                                '/assets/images/no_image.jpg'
                                            }
                                            alt="img"
                                        />
                                    </div>
                                )}
                        </ImageList>
                    </WrapperBox>
                    <WrapperBox>
                        <H1> {productInfo?.name || ''} </H1>

                        <SortTittle>{t('webstore.price')}</SortTittle>
                        <H1>${productInfo?.shop_price || ''}</H1>

                        {productInfo?.is_active &&
                            (productInfo.is_active ? (
                                <Stock>{t('webstore.instock')}</Stock>
                            ) : (
                                <NonStock>
                                    {t('webstore.out_of_stock')}
                                </NonStock>
                            ))}

                        <br />
                        <br />
                        <br />
                        <SortTittle>{t('webstore.description')}</SortTittle>

                        <P>{productInfo?.description || ''}</P>

                        <div className={classes.input_box}>
                            <label className={classes.input_box_label}>
                                {t('webstore.quantity')}
                            </label>
                            <TextField
                                id="quantity"
                                type="number"
                                placeholder="eg. 1"
                                onChange={(e) => {
                                    setQuantityData(Number(e.target.value) || 1)
                                }}
                                value={Math.abs(quantityData) || 1}
                                InputProps={{ inputProps: { min: 1 } }}
                            />
                        </div>
                        <label className={classes.input_box_label}>
                            {t('webstore.remark')}
                        </label>
                        <textarea
                            className={classes.input_text_box}
                            rows={5}
                            maxLength={250}
                        ></textarea>

                        <div style={{ marginTop: 30 }}>
                            <Button
                                onClick={add_to_cart}
                                className={classes.cart_btn}
                                variant="contained"
                            >
                                {' '}
                                {t('webstore.add_to_cart')}{' '}
                            </Button>
                        </div>
                    </WrapperBox>
                </Body>
            </Container>

            <EmptyBox></EmptyBox>
            <CartIcon />
        </>
    )
}

export default ProductDetails

const select_options = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
]
