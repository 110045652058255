import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const ProductList = Loadable(lazy(() => import('./ProductList')))
const InventoryTransaction = Loadable(
    lazy(() => import('./InventoryTransaction'))
)

const inventoryRoutes = [
    {
        path: '/inventory/product_list',
        element: <ProductList />,
    },
    {
        path: '/inventory/history',
        element: <InventoryTransaction />
    },
]

export default inventoryRoutes
