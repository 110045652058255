import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const Invoice = Loadable(lazy(() => import('./Invoice')))
const History = Loadable(lazy(() => import('./History')))
const CompanyInvoice = Loadable(lazy(() => import('./CompanyInvoice')))

const invoiceRoutes = [
    {
        path: '/invoice',
        element: <Invoice />,
    },
    {
        path: '/invoice/history',
        element: <History />,
    },
    {
        path: '/company_invoice',
        element: <CompanyInvoice />,
    },
]

export default invoiceRoutes
