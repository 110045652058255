const initialState = {
  showEdit: false,
  showCreate: false,
  data: ""
}
const StaffPageEditReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'SET_VALUE_STAFF':
      return { ...state, ...action.payload }

    default: { return state }
  }
}

export default StaffPageEditReducer
