import { createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash';
import {getDayOrDate} from 'app/utils/utils';

const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))


const handleMessageInConsistency = (messages) => {
    const lastMsgToday = messages?.Today && messages.Today[messages?.Today?.length - 1];
    const todayDate = new Date().getDate();
    const todayLastMessageDate = lastMsgToday?.create_at_desire_tz || lastMsgToday?.created_at

    if (![null, undefined].includes(lastMsgToday) && todayDate !== new Date(todayLastMessageDate).getDate()) {
        // Update yesterday key name with its appropriate name
        if (messages?.Yesterday?.length > 0) {
            const lastMsgFromYesterday = messages.Yesterday[messages.Yesterday.length - 1];
            const dayName = getDayOrDate(new Date(lastMsgFromYesterday.create_at_desire_tz));
            messages[dayName] = [...messages.Yesterday];
            delete messages["Yesterday"];
        }

        // Update today key name with its appropriate name
        const dayName = getDayOrDate(new Date(todayLastMessageDate));
        messages[dayName] = [...messages.Today];
        delete messages["Today"]
    }
    return messages || {}
}


const initialState = {
    user_list: {},
    total_user_list: 0,
    active_user: {},
    active_conversation: [],
    media_files: [],
    forward_messages: {
        active: false,
        user_dialog: false,
        marked_list: [],
        selected_user_list: []
    },
    total_unread_message_room: 0,
    shop_total_unread_message: 0
}

const ChatRoomSlice = createSlice({
    name: 'chat_room',
    initialState,
    reducers: {
        updateActiveUser: (state, { payload }) => {
            state.active_user = payload || {}
        },
        updateUserList: (state, { payload }) => {
            state.user_list = payload;
            state.total_user_list = payload?.pagination?.total_value
        },
        appendUserInList: (state, { payload }) => {
            state.user_list = payload;
        },
        updateMediaFiles: (state, { payload }) => {
            state.media_files = [
                ...state.media_files,
                ...payload
            ];
        },
        removeMediaFiles: (state, { payload }) => {
            let _media_files = _.cloneDeep(state.media_files)
            const newArray = _.filter(_media_files, item => item.index !== payload);
            state.media_files = newArray
        },
        clearMediaFiles: (state) => {
            state.media_files = []
        },
        updateMessage: (state, { payload }) => {
            state.media_files[payload.index].message = payload.value;
        },
        appendNewMessage: (state, { payload }) => {

            // Invoke the inconsistent day phases behavior change
            let messages = state.active_user.latest_messages.results;

            if (messages?.Today?.length > 0) {
                messages = handleMessageInConsistency(messages)
            }

            // Get ths last message from active room messages
            let lastMsg = null;
            const msgKeys = Object.keys(messages);
            if(msgKeys.length > 0){
                const lastKey = msgKeys[msgKeys.length - 1];
                lastMsg = messages[lastKey];
                lastMsg = lastMsg[lastMsg?.length - 1]
            }

            let currentDate = new Date()

            // Append the new message to active room
            if ( ["new_message", "template_message"].includes(payload.response_type) &&
                (state.active_user?.channel_id === payload.channel_id)
            ) {
                let today_data = messages.Today || []
                messages["Today"] = [
                    ...today_data,
                    {
                        "id": payload.temp_message_id,
                        "sender_number": payload.sender_number,
                        "message_type": payload.message_type,
                        "message_text": payload.message_text,
                        "attachment_link_url": payload.attachment_link_url,
                        "status": payload.message_status,
                        "template_message_data": payload?.template_message_data,
                        "messages_content" : payload?.template_message_data,
                        "replied_to_message_data": payload?.replied_to_message_data,
                        "created_at": currentDate.toISOString(),
                        "file_name": payload?.file_name,
                        "file_size": payload?.file_size,
                        "extension": payload?.extension,
                        "replied_shop_user_fullname": payload.replied_shop_user_fullname
                    }
                ]
                state.active_user.latest_messages.results = messages
                state.active_user.latest_activity = {
                    id : payload.temp_message_id,
                    message_type: payload.message_type,
                    messages_content: payload.message_text || payload?.template_message_data,
                    status: payload.message_status,
                    last_message_creation_time: currentDate.toISOString(),
                    sender_number: payload.sender_number,
                    sender_reaction_emoji: null,
                    receiver_reaction_emoji: null,
                    latest_reaction_sender_number : null
                }

            }

            // // Append the new message to the user list contains room
            if ( ["new_message", "template_message"].includes(payload.response_type) ) {
                // Append new message to room list
                let user_list = state.user_list.results.data

                user_list = user_list.map((room) => {
                    if (room?.channel_id === payload.channel_id) {
                        let messagesData = handleMessageInConsistency(room.latest_messages.results)
                        let today_data = messagesData?.Today || []
                        room.latest_messages.results["Today"] = [
                            ...today_data,
                            {
                                "id": payload.temp_message_id,
                                "sender_number": payload.sender_number,
                                "message_type": payload.message_type,
                                "message_text": payload.message_text,
                                "attachment_link_url": payload.attachment_link_url,
                                "status": payload.message_status,
                                "template_message_data": payload?.template_message_data,
                                "messages_content" : payload?.template_message_data,
                                "replied_to_message_data": payload?.replied_to_message_data,
                                "created_at": currentDate.toISOString(),
                                "file_name": payload?.file_name,
                                "file_size": payload?.file_size,
                                "extension": payload?.extension,
                                "replied_shop_user_fullname": payload.replied_shop_user_fullname
                            }
                        ]
                        room.latest_messages.results = messagesData
                        room.latest_activity = {
                            id : payload.temp_message_id,
                            message_type: payload.message_type,
                            messages_content: payload.message_text || payload?.template_message_data,
                            status: payload.message_status,
                            last_message_creation_time: currentDate.toISOString(),
                            sender_number: payload.sender_number,
                            sender_reaction_emoji: null,
                            receiver_reaction_emoji: null,
                            latest_reaction_sender_number : null
                        }
                        return room;
                    }
                    return room;
                });

                // sort rooms by last_message_creation_time and pin

                let clone_user_list = _.cloneDeep(user_list);
                const userIndex = _.findIndex(clone_user_list, { channel_id: payload?.chat_room_info });

                if (userIndex !== -1) {
                    _.assign(clone_user_list[userIndex], { has_pinned: payload?.response_type === "pin_room" });
                };

                const trueActions = _.filter(clone_user_list, { has_pinned: true });
                const falseActions = _.filter(clone_user_list, { has_pinned: false });
                const orderedData = _.orderBy(falseActions, obj => new Date(obj.latest_activity.last_message_creation_time), 'desc');
                let new_user_list = [...trueActions, ...orderedData];

                state.user_list.results.data = new_user_list;
            }

            // When if last message is not the actual latest message of active room then simulate room contains messages with active room message.
            if(lastMsg && lastMsg?.id !== state.active_user.latest_activity?.id && 
                ["new_message", "template_message"].includes(payload.response_type) &&
                state.active_user?.channel_id === payload.channel_id){
                let user_list = state.user_list.results.data;
                let roomActiveUser = user_list.find(user => user.channel_id === payload.channel_id)
                state.active_user.latest_messages = roomActiveUser.latest_messages
            }
        },
        appendNewMessageToInactiveTab: (state, {payload}) => {
            let currentDate = new Date()

            let messages = handleMessageInConsistency(state.active_user.latest_messages?.results)

            // Get ths last message from active room messages
            let lastMsg = null;
            const msgKeys = Object.keys(messages);

            if(msgKeys.length > 0){
                const lastKey = msgKeys[msgKeys.length - 1];
                lastMsg = messages[lastKey];
                lastMsg = lastMsg[lastMsg?.length - 1]
            }

            // Append the new message to active room
            if (["new_message", "template_message"].includes(payload.response_type) &&
                (state.active_user?.channel_id === payload.room_info.channel_id)
            ) {
                let today_data = messages?.Today || []
                messages["Today"] = [
                    ...today_data,
                    {
                        "id": payload.message.id,
                        "sender_number": payload.message.sender.number,
                        "message_type": payload.message.message_type,
                        "message_text": payload.message.message_text,
                        "attachment_link_url": payload.message.attachment_link,
                        "template_message_data": payload?.message?.template_message,
                        "replied_to_message_data": payload?.message?.replied_to_message_data,
                        "created_at": currentDate.toISOString(),
                        "file_name": payload?.message?.file_name,
                        "file_size": payload?.message?.file_size,
                        "extension": payload?.message?.extension,
                        "status": "delivered",
                        replied_shop_user_fullname: loginInfo?.user?.fullname,
                    }
                ]
                state.active_user.latest_messages.results = messages
                state.active_user.latest_activity = {
                    id :  payload.message.id,
                    message_type: payload.message.message_type,
                    messages_content: payload.message.message_text,
                    status: "delivered",
                    last_message_creation_time: currentDate.toISOString(),
                    sender_number: payload.message.sender.number,
                }

            }

            // Append the new message to the user list contains room
            if (["new_message", "template_message"].includes(payload.response_type)) {
                // Append new message to room list
                let user_list = state.user_list.results.data

                user_list = user_list.map((room) => {
                    if (room?.channel_id === payload.room_info.channel_id) {
                        let messageData = handleMessageInConsistency(room.latest_messages.results)
                        let today_data = messageData?.Today || []
                        messageData["Today"] = [
                            ...today_data,
                            {
                                "id": payload.message.id,
                                "sender_number": payload.message.sender.number,
                                "message_type": payload.message.message_type,
                                "message_text": payload.message.message_text,
                                "attachment_link_url": payload.message.attachment_link,
                                "template_message_data": payload?.message?.template_message,
                                "replied_to_message_data": payload?.message?.replied_to_message_data,
                                "created_at": currentDate.toISOString(),
                                "file_name": payload?.message?.file_name,
                                "file_size": payload?.message?.file_size,
                                "extension": payload?.message?.extension,
                                "status": "delivered",
                                replied_shop_user_fullname: loginInfo?.user?.fullname,
                            }
                        ]
                        room.latest_messages.results = messageData
                        room.latest_activity = {
                            id :  payload.message.id,
                            message_type: payload.message.message_type,
                            messages_content: payload.message.message_text,
                            status: "delivered",
                            last_message_creation_time: currentDate.toISOString(),
                            sender_number: payload.message.sender.number,
                        }
                        return room;
                    }
                    return room;
                });

                // sort rooms by last_message_creation_time and pin
                let clone_user_list = _.cloneDeep(user_list);

                const trueActions = _.filter(clone_user_list, { has_pinned: true });
                const falseActions = _.filter(clone_user_list, { has_pinned: false });
                const orderedData = _.orderBy(falseActions, obj => new Date(obj.latest_activity.last_message_creation_time), 'desc');
                let new_user_list = [...trueActions, ...orderedData];

                state.user_list.results.data = new_user_list;
            }

            // When if last message is not the actual latest message of active room then simulate room contains messages with active room message.
            if(lastMsg && lastMsg?.id !== state.active_user.latest_activity?.id && 
                ["new_message", "template_message"].includes(payload.response_type) &&
                state.active_user?.channel_id === payload.room_info.channel_id){
                
                let user_list = state.user_list.results.data;
                let roomActiveUser = user_list.find(user => user.channel_id === payload.room_info.channel_id)
                state.active_user.latest_messages = roomActiveUser.latest_messages
            }
        },
        readMessage: (state, { payload }) => {
            state.user_list.results.data = state.user_list.results.data.map((room) => {
                if (room?.channel_id === payload?.channel_id) {
                    room.unread_messages = 0;
                    let all_messages = room.latest_messages.results;

                    _.forEach(all_messages, (value, key) => {
                        _.forEach(value, (item) => {
                            item.status = 'read';
                        });
                    });

                    return room
                }
                return room;
            })
        },
        reactOnMessage: (state, { payload }) => {
            if (!payload?.channel_id) return;
            let user_index_number = _.findIndex(state?.user_list?.results?.data, { channel_id: payload?.channel_id })
            let user_message_object = state?.user_list?.results?.data[user_index_number];

            if(payload?.channel_id === user_message_object?.channel_id){                
                if(payload?.emoji){
                    if(payload?.reaction_sender === user_message_object?.latest_activity?.sender_number){
                        state.user_list.results.data[user_index_number].latest_activity = {
                            ...state?.user_list?.results?.data[user_index_number].latest_activity,
                            latest_reaction_sender_number : payload?.reaction_sender,
                            sender_reaction_emoji: payload?.emoji,
                            messages_content: payload?.messages_content,
                            message_type: payload?.message_type
                        }
                    }else{
                        state.user_list.results.data[user_index_number].latest_activity = {
                            ...state?.user_list?.results?.data[user_index_number].latest_activity,
                            latest_reaction_sender_number : payload?.reaction_sender,
                            receiver_reaction_emoji: payload?.emoji,
                            messages_content: payload?.messages_content,
                            message_type: payload?.message_type
                        }
                    }
                }else{
                    state.user_list.results.data[user_index_number].latest_activity = {
                        ...state?.user_list?.results?.data[user_index_number].latest_activity,
                        latest_reaction_sender_number : payload?.reaction_sender,
                        sender_reaction_emoji: payload?.sender_reaction_emoji,
                        receiver_reaction_emoji: payload?.receiver_reaction_emoji,
                        messages_content: payload?.messages_content,
                        message_type: payload?.message_type,
                    }
                };
            };
            
            let meg_result = user_message_object.latest_messages?.results;

            for (const key in meg_result){
                const single_day_message = meg_result[key];
                let msg_found = false;

                for (const single_mag of single_day_message){
                    
                    if(payload?.id === single_mag?.id){
                        let msg_index = _.findIndex(single_day_message, { id: payload?.id })

                        state.user_list.results.data[user_index_number]
                        .latest_messages.results[key][msg_index]
                        .latest_reaction_sender_number = payload?.reaction_sender;

                        if(payload?.emoji){
                            if(payload?.reaction_sender === user_message_object?.latest_activity?.sender_number){
                                // for use list array
                                state.user_list.results.data[user_index_number]
                                .latest_messages.results[key][msg_index]
                                .sender_reaction_emoji = payload?.emoji;

                                state.user_list.results.data[user_index_number]
                                .latest_messages.results[key][msg_index]
                                .messages_content = payload?.messages_content

                                state.user_list.results.data[user_index_number]
                                .latest_messages.results[key][msg_index]
                                .messages_type = payload?.messages_type

                                // // for active user list array
                                if(payload?.channel_id === state.active_user?.channel_id){
                                    state.active_user
                                    .latest_messages.results[key][msg_index]
                                    .sender_reaction_emoji = payload?.emoji;
                                    
                                    state.active_user
                                    .latest_messages.results[key][msg_index]
                                    .messages_content = payload?.messages_content;

                                    state.active_user
                                    .latest_messages.results[key][msg_index]
                                    .messages_type = payload?.messages_type;
                                }

                            }else{
                                // for use list array
                                state.user_list.results.data[user_index_number]
                                .latest_messages.results[key][msg_index]
                                .receiver_reaction_emoji = payload?.emoji;

                                state.user_list.results.data[user_index_number]
                                .latest_messages.results[key][msg_index]
                                .messages_content = payload?.messages_content;

                                state.user_list.results.data[user_index_number]
                                .latest_messages.results[key][msg_index]
                                .message_type = payload?.message_type;

                                if(payload?.channel_id === state.active_user?.channel_id){
                                    // for active user list array
                                    state.active_user
                                    .latest_messages.results[key][msg_index]
                                    .receiver_reaction_emoji = payload?.emoji;
                                    state.active_user
                                    .latest_messages.results[key][msg_index]
                                    .messages_content = payload?.messages_content;
                                    state.active_user
                                    .latest_messages.results[key][msg_index]
                                    .message_type = payload?.message_type;
                                }
                            };
                        }else{
                            // for use list array
                            state.user_list.results.data[user_index_number]
                            .latest_messages.results[key][msg_index]
                            .sender_reaction_emoji =  payload?.sender_reaction_emoji;

                            state.user_list.results.data[user_index_number]
                            .latest_messages.results[key][msg_index]
                            .messages_content =  payload?.messages_content;

                            state.user_list.results.data[user_index_number]
                            .latest_messages.results[key][msg_index]
                            .message_type =  payload?.message_type;

                            state.user_list.results.data[user_index_number]
                            .latest_messages.results[key][msg_index]
                            .receiver_reaction_emoji = payload?.receiver_reaction_emoji;

                            if(payload?.channel_id === state.active_user?.channel_id){
                                // for active user list array
                                state.active_user
                                .latest_messages.results[key][msg_index]
                                .sender_reaction_emoji =  payload?.sender_reaction_emoji;
    
                                state.active_user
                                .latest_messages.results[key][msg_index]
                                .receiver_reaction_emoji = payload?.receiver_reaction_emoji;

                                state.active_user
                                .latest_messages.results[key][msg_index]
                                .messages_content = payload?.messages_content;

                                state.active_user
                                .latest_messages.results[key][msg_index]
                                .message_type = payload?.message_type;
                            }
                        };

                        msg_found = true
                        break
                    };
                };

                if(msg_found){
                    break
                };
                
            };
           
        },
        deliveredMessage: (state, { payload }) => {
            if ((state.active_user?.channel_id === payload.room_info?.channel_id)) {
                let today_data = state.active_user.latest_messages?.results?.Today || []
                state.active_user.latest_messages.results["Today"] = today_data.map(msg => {                
                    if (msg.id === payload.message?.temp_message_id) {
                        state.active_user.latest_activity = {
                            ...msg,
                            id: payload.message.id,
                            status: payload?.message?.status,
                            attachment_link_url: payload.message.attachment_link,
                            message_id: payload.message?.message_id
                        }

                        return {
                            ...msg,
                            id: payload.message.id,
                            status: payload?.message?.status,
                            attachment_link_url: payload.message.attachment_link,
                            message_id: payload.message?.message_id
                        }
                    }
                    return msg
                })
            }

            // Append new message to room list
            let user_list = state.user_list.results.data

            user_list = user_list.map((room) => {
                if (room?.channel_id === payload.room_info?.channel_id) {

                    if(room.latest_activity.id === payload.message?.temp_message_id){
                        room.latest_activity.status = payload?.message?.status;
                        room.latest_activity.id = payload?.message?.id;
                    };

                    let today_data = room.latest_messages?.results?.Today || []
                    room.latest_messages.results["Today"] = today_data.map(msg => {
                        if (msg.id === payload.message?.temp_message_id) {
                            return {
                                ...msg,
                                id: payload.message.id,
                                status: payload?.message?.status,
                                attachment_link_url: payload.message.attachment_link
                            }
                        }
                        return msg
                    })
                    return room;
                }
                return room;
            });
            state.user_list.results.data = user_list
        },
        messageStatusUpdate: (state, { payload }) => {
            if (state.active_user?.channel_id === payload.room_info?.channel_id) {
                if( state.active_user.latest_activity.id === payload.message?.id){
                    state.active_user.latest_activity.status = payload.message.status;
                }

                let today_data = state.active_user.latest_messages?.results?.Today || []
                state.active_user.latest_messages.results["Today"] = today_data.map(msg => {
                    if (msg.id === payload.message?.id) {
                        return {
                            ...msg,
                            status: msg.status !== "read" ? payload.message.status : msg.status,
                        }
                    }
                    return msg
                })
            }

            // Append new message to room list
            let user_list = state.user_list.results.data

            user_list = user_list.map((room) => {
                if (room?.channel_id === payload.room_info?.channel_id) {
                    if(room.latest_activity.id === payload.message?.id){
                        room.latest_activity.status = payload.message.status;
                    }

                    let today_data = room.latest_messages.results?.Today || []
                    room.latest_messages.results["Today"] = today_data.map(msg => {
                        if (msg.id === payload.message?.id) {
                            return {
                                ...msg,
                                status: msg.status !== "read" ? payload.message.status : msg.status,
                            }
                        }
                        return msg
                    })
                    return room;
                }
                return room;
            });

            state.user_list.results.data = user_list
        },
        appendUpcomingNewMessages: (state, { payload }) => {

            let messages = handleMessageInConsistency(state.active_user.latest_messages?.results)

            // Get ths last message from active room messages
            let lastMsg = null;
            const msgKeys = Object.keys(messages);
            if(msgKeys.length > 0){
                const lastKey = msgKeys[msgKeys.length - 1];
                lastMsg = messages[lastKey];
                lastMsg = lastMsg[lastMsg?.length - 1]
            }

            // Append message to active room
            if ((state.active_user?.channel_id === payload.chat_room_info.channel_id)) {
                let today_data = messages?.Today || []

                let message = payload.chat_room_info.latest_messages.results.Today[payload.chat_room_info.latest_messages.results.Today?.length - 1]

                if(lastMsg && lastMsg?.id !== state.active_user.latest_activity?.id){
                    // Don't append upcoming messages when if last message is not the actual latest message of active room.
                } else {
                    messages["Today"] = [
                        ...today_data,
                        { ...message }
                    ]
                }

                state.active_user.latest_messages.results = messages
                state.active_user.latest_activity = payload.chat_room_info.latest_activity
                state.active_user.unread_messages = payload.chat_room_info.unread_messages
                state.active_user.expire_at = payload.chat_room_info.expire_at
            }

            // For case room exists append new upcoming message otherwise create new room
            let user_list = state.user_list.results.data
            let roomExists = user_list.some(obj => obj.channel_id === payload.chat_room_info.channel_id);

            if (roomExists) {
                user_list = user_list.map((room) => {
                    if (room?.channel_id === payload.chat_room_info.channel_id) {
                        let messagesData = handleMessageInConsistency(room.latest_messages?.results)
                        let today_data = messagesData?.Today || []
                        let message = payload.chat_room_info.latest_messages.results.Today[payload.chat_room_info.latest_messages.results.Today?.length - 1]

                        messagesData["Today"] = [
                            ...today_data,
                            { ...message }
                        ]

                        room.latest_messages.results = messagesData
                        room.latest_activity = payload.chat_room_info.latest_activity
                        room.unread_messages = payload.chat_room_info.unread_messages
                        room.expire_at = payload.chat_room_info.expire_at
                        return room;
                    }
                    return room;
                });
            } else {
                user_list.push(payload.chat_room_info)
            }

            // sort rooms by last_message_creation_time
            let clone_user_list = _.cloneDeep(user_list);
            const userIndex = _.findIndex(clone_user_list, { channel_id: payload?.chat_room_info });

            if (userIndex !== -1) {
                _.assign(clone_user_list[userIndex], { has_pinned: payload?.response_type === "pin_room" });
            };

            const trueActions = _.filter(clone_user_list, { has_pinned: true });
            const falseActions = _.filter(clone_user_list, { has_pinned: false });
            const orderedData = _.orderBy(falseActions, obj => new Date(obj.latest_activity.last_message_creation_time), 'desc');
            let new_user_list = [...trueActions, ...orderedData];

            state.user_list.results.data = new_user_list;
        },
        forwardMessageAction: (state, { payload }) => {
            state.forward_messages.active = payload;
            state.forward_messages.marked_list = [];
            state.forward_messages.selected_user_list = [];
        },
        forwardMessageUserDialog: (state, { payload }) => {
            state.forward_messages.user_dialog = payload;
        },
        forwardMarkList: (state, { payload }) => {
            let mark_list_data = _.cloneDeep(state.forward_messages.marked_list) || [];
            const existingIndex = _.findIndex(mark_list_data, { id: payload.id });

            if (existingIndex === -1) {
                state.forward_messages.marked_list = [
                    ...state.forward_messages.marked_list,
                    payload
                ]
            } else {
                _.remove(mark_list_data, { id: payload.id });
                state.forward_messages.marked_list = mark_list_data || []
            };

        },
        forwardUserList: (state, { payload }) => {
            let user_list_data = _.cloneDeep(state.forward_messages.selected_user_list) || [];
            const existingIndex = _.findIndex(user_list_data, { id: payload.id });

            if (existingIndex === -1) {
                state.forward_messages.selected_user_list = [
                    ...state.forward_messages.selected_user_list,
                    payload
                ]
            } else {
                _.remove(user_list_data, { id: payload.id });
                state.forward_messages.selected_user_list = user_list_data || []
            };

        },
        newTemplateMessageContact: (state, { payload }) => {
            // Update active user message if exists
            let activeUser = payload.chat_room_info.find(user => user.channel_id === state.active_user?.channel_id)
            if (activeUser) {
                let today_data = state.active_user?.latest_messages?.results?.Today || []
                state.active_user.latest_messages.results = {
                    ...state.active_user.latest_messages.results,
                    "Today": [
                        ...today_data,
                        ...activeUser.latest_messages?.results?.Today
                    ]
                }

                state.active_user.latest_activity = activeUser?.latest_activity
            }

            let user_list = state.user_list.results.data

            payload.chat_room_info.forEach(contact => {
                let existingUser = user_list.find(user => user.channel_id === contact.channel_id)

                if (existingUser) {
                    user_list = user_list.map((room) => {
                        if (room?.channel_id === payload.channel_id) {
                            let today_data = room.latest_messages?.results?.Today || []
                            room.latest_messages.results["Today"] = [
                                ...today_data,
                                ...existingUser.latest_messages?.results?.Today
                            ]
                            room.latest_activity = activeUser?.latest_activity
                            return room;
                        }
                        return room;
                    });
                } else {
                    user_list = [
                        contact,
                        ...user_list
                    ]
                }

                state.user_list.results.data = user_list
            })

            // sort rooms by last_message_creation_time
            let clone_user_list = _.cloneDeep(user_list);
            const userIndex = _.findIndex(clone_user_list, { channel_id: payload?.chat_room_info });

            if (userIndex !== -1) {
                _.assign(clone_user_list[userIndex], { has_pinned: payload?.response_type === "pin_room" });
            };

            const trueActions = _.filter(clone_user_list, { has_pinned: true });
            const falseActions = _.filter(clone_user_list, { has_pinned: false });
            const orderedData = _.orderBy(falseActions, obj => new Date(obj.latest_activity.last_message_creation_time), 'desc');
            let new_user_list = [...trueActions, ...orderedData];

            state.user_list.results.data = new_user_list
        },
        replyMessage: (state, { payload }) => {
            state.active_user['reply_message'] = payload
        },
        clearReplyMessage: (state, { payload }) => {
            state.active_user['reply_message'] = null
        },
        appendFreeScrollingFetchMessages: (state, { payload }) => {
            state.active_user.latest_messages.pagination = payload.data.pagination

            if (payload.data.results?.hasOwnProperty(payload.lastMsgKey)) {
                state.active_user.latest_messages.results = {
                    ...state.active_user.latest_messages.results,
                    [payload.lastMsgKey]: [
                        ...payload.data.results?.[payload.lastMsgKey],
                        ...state.active_user.latest_messages.results?.[payload.lastMsgKey],
                    ]
                }
                delete payload.data.results?.[payload.lastMsgKey]
            }

            state.active_user.latest_messages.results = {
                ...payload.data.results,
                ...state.active_user.latest_messages.results,
            }
        },
        appendFreeScrollingFetchBottomMessages: (state, { payload }) => {
            state.active_user.latest_messages.pagination = payload.data.pagination

            if (payload.data.results?.hasOwnProperty(payload.lastMsgKey)) {
                state.active_user.latest_messages.results = {
                    ...state.active_user.latest_messages.results,
                    [payload.lastMsgKey]: [
                        ...state.active_user.latest_messages.results?.[payload.lastMsgKey],
                        ...payload.data.results?.[payload.lastMsgKey],
                    ]
                }
                delete payload.data.results?.[payload.lastMsgKey]
            }

            state.active_user.latest_messages.results = {
                ...state.active_user.latest_messages.results,
                ...payload.data.results,
            }
        },
        totalUnreadMessageRoom: (state, { payload }) => {
            if (![undefined, null].includes(payload?.total_unread_message_room)) {
                state.total_unread_message_room = payload.total_unread_message_room;
                state.shop_total_unread_message = payload.shop_total_unread_message;
            }
        },
        toggleMute: (state, { payload }) => {
            if (state?.user_list?.results?.data) {
                const index = _.findIndex(state?.user_list?.results?.data, { channel_id: payload?.chat_room_info })
                state.user_list.results.data[index].mute_type = payload.mute_type;
                state.user_list.results.data[index].muted_at = payload.muted_at;
            }
        },
        togglePin: (state, { payload }) => {
            let pin_type = payload?.response_type === "pin_room";
            let user_list = state?.user_list?.results?.data;

            const index = _.findIndex(state?.user_list?.results?.data, { channel_id: payload?.chat_room_info })
            state.user_list.results.data[index].pinned_at = pin_type;


            let clone_user_list = _.cloneDeep(user_list);
            const userIndex = _.findIndex(clone_user_list, { channel_id: payload?.chat_room_info });

            if (userIndex !== -1) {
                _.assign(clone_user_list[userIndex], { has_pinned: payload?.response_type === "pin_room" });
            };

            const trueActions = _.filter(clone_user_list, { has_pinned: true });
            const falseActions = _.filter(clone_user_list, { has_pinned: false });
            const orderedData = _.orderBy(falseActions, obj => new Date(obj.latest_activity.last_message_creation_time), 'desc');
            let new_user_list = [...trueActions, ...orderedData];

            state.user_list.results.data = new_user_list;

        },
        appendSearchSelectedMessages: (state, { payload }) => {
            state.active_user.latest_messages = payload
        },
        removeAppendedErrorContainMessage: (state, { payload }) => {
            // Remove active user
            const { Today } = state.active_user.latest_messages.results;
            const updatedToday = _.filter(Today, (message) => {
                return message.id !== payload.message_id;
            });

            state.active_user.latest_messages.results.Today = updatedToday
            if(updatedToday?.length === 0){
                delete state.active_user.latest_messages.results["Today"]
            }
            
            // Remove from list of user messages storage
            let user_list = state.user_list.results.data

            user_list = user_list.map((room) => {
                if (room?.channel_id === state.active_user.channel_id) {
                    const { Today } = state.active_user.latest_messages.results;
                    const updatedToday = _.filter(Today, (message) => {
                        return message.id !== payload.message_id;
                    });

                    room.latest_messages.results.Today = updatedToday
                    if(updatedToday?.length === 0){
                        delete room.latest_messages.results["Today"]
                    }

                    // Update room latest message
                    let lastMsg = null;
                    const msgKeys = Object.keys(room.latest_messages.results);
                    if(msgKeys.length > 0){
                        const lastKey = msgKeys[msgKeys.length - 1];
                        lastMsg = room.latest_messages.results[lastKey];
                        lastMsg = lastMsg[lastMsg?.length - 1]
                    }

                    if(lastMsg !== null){
                        room.latest_activity = {
                            id : lastMsg.id,
                            message_type: lastMsg.message_type,
                            messages_content: lastMsg.message_text || lastMsg?.template_message_data,
                            status: lastMsg.status,
                            last_message_creation_time: lastMsg.create_at_desire_tz,
                            sender_number: lastMsg.sender_number,
                            sender_reaction_emoji: lastMsg.sender_reaction_emoji,
                            receiver_reaction_emoji: lastMsg.receiver_reaction_emoji,
                            latest_reaction_sender_number : lastMsg.latest_reaction_sender_number
                        }
                    }
                    return room;
                }
                return room;
            });
            state.user_list.results.data = user_list
        },

    }
})

export const {
    updateActiveUser,
    updateUserList,
    appendUserInList,
    updateMediaFiles,
    removeMediaFiles,
    clearMediaFiles,
    updateMessage,
    appendNewMessage,
    readMessage,
    reactOnMessage,
    deliveredMessage,
    messageStatusUpdate,
    appendUpcomingNewMessages,
    forwardMessageAction,
    forwardMessageUserDialog,
    forwardMarkList,
    forwardUserList,
    newTemplateMessageContact,
    replyMessage,
    clearReplyMessage,
    appendFreeScrollingFetchMessages,
    toggleMute,
    totalUnreadMessageRoom,
    togglePin,
    appendSearchSelectedMessages,
    appendFreeScrollingFetchBottomMessages,
    appendNewMessageToInactiveTab,
    removeAppendedErrorContainMessage
} = ChatRoomSlice.actions

export default ChatRoomSlice.reducer