import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const DiscountList = Loadable(lazy(() => import('./DiscountList')))
const CouponList = Loadable(lazy(() => import('./CouponList')))
const RoomList = Loadable(lazy(() => import('./RoomList')))
const ChannelList = Loadable(lazy(() => import('./ChannelList')))
const PaymentMethodList = Loadable(lazy(() => import('./PaymentMethodList')))
const SystemSecurityLock = Loadable(lazy(() => import('./SystemSecurityLock')))

const settingRoutes = [
    {
        path: '/setting/discount_list',
        element: <DiscountList />
    },
    {
        path: '/setting/coupon_list',
        element: <CouponList />
    },
    {
        path: '/setting/room_list',
        element: <RoomList />
    },
    {
        path: '/setting/channel_list',
        element: <ChannelList />
    },
    {
        path: '/setting/payment_method_list',
        element: <PaymentMethodList />
    },
    {
        path: '/setting/security_lock',
        element: <SystemSecurityLock />
    },
]

export default settingRoutes
